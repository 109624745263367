import React from 'react'
import PropTypes from 'prop-types'
import PublicModalDrawer from 'components/PublicModalDrawer'
import PreferencesSection from 'components/sections/PreferencesSection'

const CustomerPreferencesModal = ({ isOpen = false, onClose = () => { } }) => {
  return (
    <PublicModalDrawer
      className="h-full pt-0 pb-0 pl-0 pr-0 sm:px-0"
      isOpen={isOpen}
      onClose={() => onClose()}
      iconClassName="text-white"
      showHeader={false}
    >
      <PreferencesSection
        onFinish={() => onClose()}
        isDrawer={true}
      />
    </PublicModalDrawer>
  )
}

CustomerPreferencesModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
}

export default CustomerPreferencesModal
