import classNames from 'classnames'
import { ComponentProps } from 'react'

export type HeaderTag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p'

interface PropTypes extends ComponentProps<'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'> {
  Tag?: HeaderTag
}

const defaultTitleClassNames = 'leading-title font-serif font-bold tracking-title'
const defaultClassNames = 'leading-title font-serif font-bold -tracking-1/2'

// Default
const H1 = ({ Tag = 'h1' , children, className, ...rest }: PropTypes) => (
  <Tag
    className={classNames(
      defaultTitleClassNames,
      'text-22',
      className,
    )}
    {...rest}
  >
    {children}
  </Tag>
)

const H2 = ({ Tag = 'h2' , children, className, ...rest }: PropTypes) => (
  <Tag
    className={classNames(
      defaultClassNames,
      'text-20',
      className,
    )}
    {...rest}
  >
    {children}
  </Tag>
)

const H3 = ({ Tag = 'h3' , children, className, ...rest }: PropTypes) => (
  <Tag
    className={classNames(
      defaultClassNames,
      'text-18 lg:text-26',
      className,
    )}
    {...rest}
  >
    {children}
  </Tag>
)

const H4 = ({ Tag = 'h4' , children, className = '', ...rest }: PropTypes) => (
  <Tag
    className={classNames(
      defaultClassNames,
      'text-16',
      className,
    )}
    {...rest}
  >
    {children}
  </Tag>
)

const H5 = ({ Tag = 'h5' , children, className = '', ...rest }: PropTypes) => (
  <Tag
    className={classNames(
      defaultClassNames,
      'text-14',
      className,
    )}
    {...rest}
  >
    {children}
  </Tag>
)

const H6 = ({ Tag = 'h6' , children, className = '', ...rest }: PropTypes) => (
  <Tag
    className={classNames(
      defaultClassNames,
      'text-12',
      className,
    )}
    {...rest}
  >
    {children}
  </Tag>
)

// Custom
const MD = ({ Tag = 'h2' , children, className, ...rest }: PropTypes) => (
  <Tag
    className={classNames(
      defaultClassNames,
      'text-22',
      className,
    )}
    {...rest}
  >
    {children}
  </Tag>
)

const LG = ({ Tag = 'h2' , children, className, ...rest }: PropTypes) => (
  <Tag
    className={classNames(
      defaultClassNames,
      'text-24',
      className,
    )}
    {...rest}
  >
    {children}
  </Tag>
)

const XL = ({ Tag = 'h2' , children, className, ...rest }: PropTypes) => (
  <Tag
    className={classNames(
      defaultClassNames,
      'text-26 md:text-28 lg:text-30',
      className,
    )}
    {...rest}
  >
    {children}
  </Tag>
)

const XXL = ({ Tag = 'h2' , children, className, ...rest }: PropTypes) => (
  <Tag
    className={classNames(
      defaultClassNames,
      'text-28 md:text-30 lg:text-32',
      className,
    )}
    {...rest}
  >
    {children}
  </Tag>
)

const PageHeader = ({ Tag = 'h1' , children, className, ...rest }: PropTypes) => (
  <Tag
    className={classNames(
      defaultTitleClassNames,
      'text-22 md:text-36',
      className,
    )}
    {...rest}
  >
    {children}
  </Tag>
)

const Title = ({ Tag = 'h1' , children, className, ...rest }: PropTypes) => (
  <Tag
    className={classNames(
      defaultTitleClassNames,
      'text-32 md:text-42 lg:text-48',
      className,
    )}
    {...rest}
  >
    {children}
  </Tag>
)

const Image = ({ Tag = 'p' , children, className, ...rest }: PropTypes) => (
  <Tag
    className={classNames(
      defaultTitleClassNames,
      'text-36 lg:text-52',
      className,
    )}
    {...rest}
  >
    {children}
  </Tag>
)

const CarouselTitle = ({ Tag = 'p' , children, className, ...rest }: PropTypes) => (
  <Tag
    className={classNames(
      defaultClassNames,
      'text-[28px] md:text-42',
      className,
    )}
    {...rest}
  >
    {children}
  </Tag>
)

const UserDashboard = ({ Tag = 'p' , children, className, ...rest }: PropTypes) => (
  <Tag
    className={classNames(
      defaultTitleClassNames,
      'text-48',
      className,
    )}
    {...rest}
  >
    {children}
  </Tag>
)

const Property = ({ Tag = 'p' , children, className, ...rest }: PropTypes) => (
  <Tag
    className={classNames(
      defaultClassNames,
      'md:text-24 text-[19px]',
      className,
    )}
    {...rest}
  >
    {children}
  </Tag>
)

const Concierge = ({ Tag = 'p' , children, className, ...rest }: PropTypes) => (
  <Tag
    className={classNames(
      defaultClassNames,
      'text-20 leading-28',
      className,
    )}
    {...rest}
  >
    {children}
  </Tag>
)

// Unsized
const BaseHeader = ({ Tag = 'h2' , children, className, ...rest }: PropTypes) => (
  <Tag
    className={classNames(
      'font-bold font-serif -tracking-1/2',
      className,
    )}
    {...rest}
  >
    {children}
  </Tag>
)

const BaseTitle = ({ Tag = 'h2' , children, className, ...rest }: PropTypes) => (
  <Tag
    className={classNames(
      'font-bold font-serif leading-title tracking-title',
      className,
    )}
    {...rest}
  >
    {children}
  </Tag>
)

export default {
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  MD,
  LG,
  XL,
  XXL,
  PageHeader,
  Image,
  Title,
  CarouselTitle,
  UserDashboard,
  Property,
  Concierge,
  // Unsized
  BaseHeader,
  BaseTitle,
}
