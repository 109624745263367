import { EditCustomerProfileSection } from './EditCustomerProfileModal'
import { ModalProps } from 'data/types/modal'
import PublicMobileModal from 'components/PublicMobileModal'
import Button from 'components/home/common/Button'
import { FieldError, useForm } from 'react-hook-form'
import Form, { SelectOption } from 'components/form/Home'
import { formatEnum } from '@ama-selections/ui'
import { AddressInput, UpdateUserInput } from 'gql/graphql'
import useAuth from 'data/hooks/User/useAuth'
import { useMutation } from 'urql'
import { graphql } from 'gql'
import useErrorHandlerGraphQl from 'data/hooks/useErrorHandlerGraphQl'
import { useDispatch } from 'react-redux'
import { setUser } from 'store/auth'
import { useNotifier } from 'react-headless-notifier'
import Notification from 'components/Notification'

export interface EditCustomerModalProps extends ModalProps {
  fields: EditCustomerProfileSection[]
}

type EditCustomerModalFormFields = Omit<UpdateUserInput, 'address' | 'id' | 'phone_country_code'> & {
  phone_country_code?: SelectOption | undefined
  address?: Omit<AddressInput, 'country'> & {
    country: SelectOption | undefined
  }
}

export const EditCustomerModal = ({
  isOpen,
  onClose,
  fields,
}: EditCustomerModalProps) => {
  const { notify } = useNotifier()
  const handleError = useErrorHandlerGraphQl()
  const { user } = useAuth()
  const dispatch = useDispatch()

  const { control, reset, register, formState: { errors }, handleSubmit, setError } = useForm<EditCustomerModalFormFields>()

  const resetForm = () => {
    const initialValues: EditCustomerModalFormFields = {}

    fields.forEach((field) => {
      switch (field) {
        case EditCustomerProfileSection.FirstName:
          initialValues.first_name = user?.first_name
          break
        case EditCustomerProfileSection.LastName:
          initialValues.last_name = user?.last_name
          break
        case EditCustomerProfileSection.Email:
          initialValues.email = user?.email
          break
        case EditCustomerProfileSection.Phone:
          initialValues.phone_country_code = user?.phone_country_code
            ? {
              label: `(+${user.phone_country_code})`,
              value: user.phone_country_code,
            }
            : undefined
          initialValues.phone = user?.phone
          break
        case EditCustomerProfileSection.Address:
          initialValues.address = {
            line_1: user?.address?.line_1,
            line_2: user?.address?.line_2,
            city: user?.address?.city,
            state: user?.address?.state,
            country: user?.address?.country
              ? {
                label: user.address.country,
                value: user.address.country,
              }
              : undefined,
            postcode: user?.address?.postcode,
          }
          break
        case EditCustomerProfileSection.DOB:
          initialValues.date_of_birth = user?.date_of_birth
          break
      }
    })

    reset(initialValues)
  }

  const [{ fetching: isUpdating }, updateUser] = useMutation(
    graphql(`
      mutation UpdateUser($input: UpdateUserInput!) {
        updateUser(input: $input) {
          id
          first_name
          last_name
          email
          phone
          address {
            id
            line_1
            line_2
            city
            state
            country
            postcode
            single_line
          }
          date_of_birth
        }
      }
    `),
  )

  const handleUpdateUser = (data: EditCustomerModalFormFields) => {
    updateUser({
      input: {
        id: user!.id,
        ...data,
        phone_country_code: data.phone_country_code?.value,
        address: {
          ...data.address,
          country: data.address?.country?.value,
        },
      },
    }).then((result) => {
      if (result.error) {
        if (result.error.graphQLErrors.some((error) => error.extensions?.validation)) {
          handleError(result.error, setError)
        } else {
          notify(
            <Notification
              message="Failed to update profile, please try again."
              type="error"
            />,
          )
        }
      } else {
        const newUser = { ...user! }
        const resultData = result.data?.updateUser

        fields.forEach((field) => {
          switch (field) {
            case EditCustomerProfileSection.FirstName:
            case EditCustomerProfileSection.LastName:
            case EditCustomerProfileSection.Email:
            case EditCustomerProfileSection.Address:
            case EditCustomerProfileSection.DOB:
              if (data?.[field] !== undefined) {
                newUser[field] = resultData?.[field]
              }
              break
            case EditCustomerProfileSection.Phone:
              if (resultData?.phone) {
                const [phoneCountryCode, phone] = (resultData.phone.replace('(+', '')).split(') ')

                newUser.phone = phone
                newUser.phone_country_code = phoneCountryCode
              }
              break
          }
        })

        dispatch(setUser(newUser))

        onClose()
      }
    })
  }

  return (
    <PublicMobileModal
      hasFocusTrap
      isOpen={isOpen}
      onClose={onClose}
      onOpen={resetForm}
      className={{
        panel: 'px-30 pb-30 pt-70',
      }}
    >
      <form
        className="flex flex-col gap-20"
        onSubmit={handleSubmit(handleUpdateUser)}
      >
        {
          fields.includes(EditCustomerProfileSection.FirstName) && (
            <Form.Input
              variant="grey"
              label={formatEnum(EditCustomerProfileSection.FirstName)}
              {...register(EditCustomerProfileSection.FirstName)}
              error={errors.first_name?.message}
            />
          )
        }

        {
          fields.includes(EditCustomerProfileSection.LastName) && (
            <Form.Input
              variant="grey"
              label={formatEnum(EditCustomerProfileSection.LastName)}
              {...register(EditCustomerProfileSection.LastName)}
              error={errors.last_name?.message}
            />
          )
        }

        {
          fields.includes(EditCustomerProfileSection.Email) && (
            <Form.Input
              variant="grey"
              label={formatEnum(EditCustomerProfileSection.Email)}
              {...register(EditCustomerProfileSection.Email)}
              error={errors.email?.message}
            />
          )
        }

        {
          fields.includes(EditCustomerProfileSection.Phone) && (
            <>
              <Form.ControlledCountryCodeSelect
                control={control}
                name="phone_country_code"
                className={{ options: {
                  container: '!max-h-200',
                } }}
                error={errors?.phone_country_code as (FieldError | undefined)}
                innerLabel="Country Calling Code"
              />

              <Form.Input
                variant="grey"
                label="Phone Number"
                error={errors?.[EditCustomerProfileSection.Phone]?.message}
                {...register(EditCustomerProfileSection.Phone)}
              />
            </>
          )
        }

        {
          fields.includes(EditCustomerProfileSection.Address) && (
            <>
              <Form.Input
                variant="grey"
                label="Address Line 1"
                error={errors?.address?.line_1?.message}
                {...register('address.line_1')}
              />

              <Form.Input
                variant="grey"
                label="Address Line 2"
                error={errors?.address?.line_2?.message}
                {...register('address.line_2')}
              />

              <Form.Input
                variant="grey"
                label="City"
                error={errors?.address?.city?.message}
                {...register('address.city')}
              />

              <Form.Input
                variant="grey"
                label="State"
                error={errors?.address?.state?.message}
                {...register('address.state')}
              />

              <Form.ControlledCountrySelect
                control={control}
                name="address.country"
                error={errors?.address?.country}
                className={{ options: {
                  container: '!max-h-200',
                } }}
                innerLabel="Country"
              />

              <Form.Input
                variant="grey"
                label="Postcode"
                error={errors?.address?.postcode?.message}
                {...register('address.postcode')}
              />
            </>
          )
        }

        {
          fields.includes(EditCustomerProfileSection.DOB) && (
            <Form.ControlledDateInput
              control={control}
              name="date_of_birth"
              error={errors?.date_of_birth as (FieldError | undefined)}
            />
          )
        }

        <Button
          className={{
            button: 'mt-50',
          }}
          style="modal"
          type="submit"
          isLoading={isUpdating}
        >
          Save
        </Button>
      </form>
    </PublicMobileModal>
  )
}
