import { useEffect, useMemo, useState } from 'react'
import UserPendingModalItem, { ConnectedServicesModalState } from './UserPendingModalItem'
import { LoadingIcon } from 'components/icons'
import { isCheckInOrOut } from 'data/helpers/trip-schedule'
import { hasDuePayments } from 'data/helpers/trip-schedule-group'
import { ModalProps } from 'data/types/modal'
import { Booking, QueryTripScheduleGroupsWhereColumn, TripScheduleServiceStatusType } from 'gql/graphql'
import PublicMobileModal from 'components/PublicMobileModal'
import Headers from 'components/home/common/typography/Headers'
import { formatEnum, formatShortCurrency, joinBy } from '@ama-selections/ui'
import { graphql } from 'gql'
import { useQuery } from 'urql'
import TabGroup from 'components/home/common/TabGroup'
import Paragraphs from 'components/home/common/typography/Paragraphs'
import Button, { Label, StickyFooter } from 'components/home/common/Button'
import { useMutation } from 'react-query'
import ApiClient from 'data/api/api_client'
import classNames from 'classnames'

interface UserPendingTripServicesModalProps extends ModalProps {
  bookingId?: Booking['id']
  onApprove?: () => void
  initialTab?: TripScheduleServiceStatusType
}

const UserPendingTripServicesModal = ({
  isOpen,
  onClose,
  bookingId,
  onApprove,
  initialTab,
}: UserPendingTripServicesModalProps) => {
  const [connectedServicesModal, setConnectedServicesModal] = useState<ConnectedServicesModalState>({
    isOpen: false,
    tripGroup: null,
  })

  const statusTabs = [
    TripScheduleServiceStatusType.PendingPayment,
    TripScheduleServiceStatusType.PendingApproval,
  ]

  const [statusTab, setStatusTab] = useState<typeof statusTabs[number]>(initialTab ?? statusTabs[0])
  useEffect(() => {
    if (isOpen) {
      setStatusTab(initialTab ?? statusTabs[0])
    }
  }, [isOpen])

  const [{ data, fetching }, refetch] = useQuery({
    query: graphql(`
      query UserPendingTripServices(
        $where: QueryTripScheduleGroupsWhereWhereConditions
      ) {
        tripScheduleGroups(
          first: 30,
          page: 1,
          where: $where
        ) {
          data {
            id

            booking {
              id
              currency
            }

            title
            subtitle
            status
            adults
            children

            main_image {
              id
              url
            }

            days {
              id
              date
              start_time
              end_time
            }

            payment_statuses
            payment_totals {
              completed
              pending
            }

            price
            customer_service_quote
            customer_service_fee
            customer_credit_card_fee
          }
        }
      }
    `),
    variables: {
      where: {
        AND: [
          {
            column: QueryTripScheduleGroupsWhereColumn.BookingId,
            value: bookingId,
          },
          {
            column: QueryTripScheduleGroupsWhereColumn.Status,
            value: statusTab,
          },
        ],
      },
    },
    pause: !bookingId,
  })

  const tripGroups = useMemo(
    () => (data?.tripScheduleGroups?.data ?? [])
      ?.filter((group) => !isCheckInOrOut(group?.title))
      ?.filter((group) => statusTab === TripScheduleServiceStatusType.PendingPayment
        ? hasDuePayments(group.payment_statuses)
        : true,
      ),
    [data],
  )

  const onSuccess = () => {
    onApprove?.()
    refetch({ requestPolicy: 'cache-and-network' })
    if (tripGroups.length === 0) {
      onClose()
    }
  }

  const approveMutation = useMutation(
    (groupId?: string) => ApiClient.tripSchedule.approveTripScheduleServices(bookingId, {
      group_id: groupId,
    }),
    {
      onSuccess: () => onSuccess(),
    },
  )

  return (
    <PublicMobileModal
      isOpen={isOpen}
      onClose={onClose}
      className={{
        panel: 'h-full lg:!h-full flex flex-col px-24 gap-y-10',
      }}
      isChildModalOpen={connectedServicesModal.isOpen}
    >
      <div className="sticky top-0 z-10 flex flex-col w-full bg-white pt-18">
        <Headers.H1 Tag="h3" className="text-center mb-30 leading-28">
          Pending Services
        </Headers.H1>

        <TabGroup.Default
          className={{
            container: 'mx-auto mb-15',
            list: '!bg-blue-25',
          }}
          items={statusTabs?.map((status) => ({
            label: formatEnum(status),
            value: status,
          }))}
          initialIndex={statusTabs.indexOf(initialTab ?? statusTabs[0])}
          onChange={({ value }) => setStatusTab(value as TripScheduleServiceStatusType)}
        />
      </div>

      {
        fetching
          ? (
            <div className="flex items-center justify-center p-50">
              <LoadingIcon />
            </div>
          )
          : (
            <>
              {
                tripGroups?.length === 0
                  ? (
                    <Paragraphs.MD className="m-auto text-center underline text-grey-800">
                      No Services {formatEnum(statusTab)}
                    </Paragraphs.MD>
                  )
                  : tripGroups?.map((tripGroup, index) => (
                    <UserPendingModalItem
                      key={`pending-trip-service-${index}`}
                      tripGroup={tripGroup}
                      action={onSuccess}
                      connectedServicesModal={connectedServicesModal}
                      setConnectedServicesModal={setConnectedServicesModal}
                    />
                  ))
              }

              <StickyFooter className={classNames({
                'mt-auto': tripGroups.length > 0,
              })}>
                <Button
                  style="modal"
                  block
                  type="submit"
                  className={{
                    button: 'max-w-xs',
                  }}
                  isLoading={approveMutation.isLoading}
                  {...(statusTab === TripScheduleServiceStatusType.PendingApproval
                    ? { onClick: () => approveMutation.mutate(undefined) }
                    : { href: `/home/reservations/${bookingId}/payments-and-invoices/service-groups/${joinBy(tripGroups.map((tripGroup) => tripGroup.id), ',')}` }
                  )}
                  disabled={tripGroups.length === 0}
                >
                  {
                    statusTab === TripScheduleServiceStatusType.PendingApproval
                      ? 'Approve All'
                      : 'Pay & Confirm All'
                  }
                </Button>

                <Label variant="dark-grey">
                  {
                    statusTab === TripScheduleServiceStatusType.PendingApproval
                      ? <>&nbsp;</>
                      : `Total Due ${formatShortCurrency(tripGroups.reduce((acc, group) => acc + (group.price ?? 0), 0), tripGroups[0]?.booking?.currency)}`
                  }
                </Label>
              </StickyFooter>
            </>
          )
      }
    </PublicMobileModal>
  )
}

export default UserPendingTripServicesModal
