import React, { useEffect, useState } from 'react'
import { useNotifier } from 'react-headless-notifier'
import useErrorHandler from 'data/hooks/useErrorHandler'
import { FormProvider, useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import ApiClient from 'data/api/api_client'
import Notification from 'components/Notification'
import { formatDefault } from 'data/helpers/dates'
import CenteredCustomerLoginModal from 'components/modals/getHelp/CenteredCustomerLoginModal'
import { config } from 'data/config'
import Script from 'next/script'
import { triggerInquiryForm } from 'data/helpers/gtm'
import { DestinationRegion } from 'gql/graphql'
import useAuth from 'data/hooks/User/useAuth'
import { InquiryType } from 'data/types/inquiry'
import PublicMobileModal from 'components/PublicMobileModal'
import classNames from 'classnames'
import HelpAssets from './HelpAssets'
import { DateRangeInput } from 'components/dates/ControlledDateRangePickerModal'
import { CheckCircleIcon } from 'components/icons'
import { countries } from 'countries-list'
import { PhoneLocation } from 'data/api/locations_api_client'
import SectionHeaders from 'components/home/common/typography/SectionHeaders'
import Paragraphs from 'components/home/common/typography/Paragraphs'
import GetHelpCallbackForm from './GetHelpCallbackForm'
import GetHelpGeneralForm from './GetHelpGeneralForm'
import { RestUser } from 'data/types/user'
import { ModalProps } from 'data/types/modal'

interface GetHelpModalProps extends ModalProps {
  initialType?: InquiryType
  region?: DestinationRegion
  onSuccess?: () => void
}

export interface InquiryDetailsFormFields {
  first_name: string
  last_name: string
  phone_number: string
  country_code: {
    label?: undefined;
    data: PhoneLocation;
    value: string;
  }
  message: string
  dates: DateRangeInput
  guests: string
  budget: string
  destination: string
  destination_id: string
}

const GetHelpModal = ({
  isOpen,
  onClose,
  initialType,
  region,
  onSuccess,
}: GetHelpModalProps) => {
  const { notify } = useNotifier()
  const { user } = useAuth()

  const handleError = useErrorHandler()

  const [modalType, setModalType] = useState(initialType)
  const [isSent, setIsSent] = useState(false)
  const [recaptchaLoaded, setRecaptchaLoaded] = useState(true)
  const [isChildModalOpen, setIsChildModalOpen] = useState(false)

  const form = useForm<InquiryDetailsFormFields>({})
  const { setError, clearErrors, reset, getValues } = form

  const { mutate: createInquiryMutation, isLoading: isInquiryMutationLoading } = useMutation(
    (details: InquiryDetailsFormFields) => {
      if (modalType === InquiryType.Callback) {
        return ApiClient.inquiries.createInquiry({
          first_name: details?.first_name,
          last_name: details?.last_name,
          phone_number: details?.phone_number,
          country_code: details?.country_code?.data?.phone_code
            ?? details.country_code.value
            ?? details.country_code,
          message: details?.message,
        }, InquiryType.Callback)
      } else {
        return ApiClient.inquiries.createInquiry({
          arrival: formatDefault(details.dates?.startDate),
          departure: formatDefault(details.dates?.endDate),
          guests: details?.guests,
          budget: details?.budget,
          message: details?.message,
          destination: region?.value ?? undefined,
          destination_id: region?.id ?? undefined,
        }, InquiryType.General)
      }
    },
    {
      onSuccess: (_, variables) => {
        if (modalType === 'callback' || !user) {
          triggerInquiryForm({
            email: user?.email ?? null,
            countryCode: variables?.country_code?.data?.phone_code,
            phone: variables?.phone_number,
          })
        } else {
          triggerInquiryForm({
            email: user.email,
            countryCode: user.phone_country_code,
            phone: user.phone,
          })
        }
        onSuccess?.()
        notify(
          <Notification type="success" message="Inquiry sent!" />,
        )
        setIsSent(true)
        reset()
      },
      onError: (error) => {
        clearErrors()
        handleError(error, setError)
      },
    },
  )

  const recaptchaVerifyMutation = useMutation(
    (token: string) => ApiClient.auth.verifyWithRecaptcha(token),
    {
      onSuccess: (data) => {
        if (data && data?.success === true && data?.score >= 0.5) {
          createInquiryMutation(getValues())
        } else {
          notify(
            <Notification type="error" message="There was an error submitting this request, please try again" />,
          )
        }
      },
      onError: () => {
        notify(
          <Notification type="error" message="There was an error submitting this request, please try again" />,
        )
      },
    },
  )

  const onSubmit = (details: InquiryDetailsFormFields) => {
    if (recaptchaLoaded) {
      if (config.recaptchaSiteKey && !user) {
        (window as any).grecaptcha.ready(function () {
          (window as any).grecaptcha.execute(config.recaptchaSiteKey, { action: 'submit_callback_inquiry' }).then(function (token: string) {
            recaptchaVerifyMutation.mutate(token)
          })
        })
      } else {
        createInquiryMutation(details)
      }
    } else {
      notify(
        <Notification type="error" message="There was an error submitting this request, please try again" />,
      )
    }
  }

  useEffect(() => {
    if (isOpen) {
      setIsSent(false)
    }
  }, [isOpen])

  useEffect(() => {
    setModalType(initialType)
  }, [initialType])
  const [loginModalOpen, setLoginModalOpen] = useState(false)

  const changeModalType = (type: InquiryType) => {
    setModalType(type)
  }

  const getPhoneData = (user: RestUser) => {
    if (user.phone_country_code) {
      const country = countries[user.location?.code as keyof typeof countries]

      return {
        data: {
          code: user.location?.code ?? '',
          name: country?.name,
        },
        value: user.phone_country_code,
      }
    }
  }

  useEffect(() => {
    if (user) {
      reset({
        first_name: user?.first_name,
        last_name: user?.last_name,
        phone_number: user?.phone ?? '',
        country_code: getPhoneData(user),
      })
    }
  }, [user, modalType])

  return (
    <PublicMobileModal
      isOpen={isOpen}
      onClose={onClose}
      className={{
        panel: classNames(
          'px-20 py-50 lg:p-50',
          { '!max-w-[1080px]': !isSent },
        ),
      }}
      isChildModalOpen={isChildModalOpen}
    >
      <FormProvider {...form}>
        {config.recaptchaSiteKey && !user
          ? <Script
            id="grecaptcha"
            src={`https://www.google.com/recaptcha/api.js?render=${config.recaptchaSiteKey}`}
            onLoad={() => setRecaptchaLoaded(true)}
          />
          : null
        }

        { isSent
          ? (<SuccessChildren />)
          : (
            <div className="flex flex-col gap-20 antialiased lg:flex-row lg:gap-50">
              <div className="flex flex-col lg:max-w-[400px] w-full gap-15">
                <p className="font-bold uppercase text-grey-750 text-14">
                  {modalType === InquiryType.Callback
                    ? 'Request a callback'
                    : 'Send us a message'
                  }
                </p>
                <h3 className="flex-1 lg:max-w-[400px] text-32 md:text-36 xl:text-48 font-bold font-serif leading-title mb-10 md:mb-30">
                  {modalType === InquiryType.Callback
                    ? 'Let us know when you’re available for a call.'
                    : <span>How can we help?<br />We’re here to assist you.</span>
                  }
                </h3>
                <div className="lg:max-w-[400px] flex flex-col gap-10 md:gap-15">
                  {modalType === InquiryType.General && (
                    <HelpAssets.RequestCallbackButton onClick={() => changeModalType(InquiryType.Callback)} />
                  )}

                  <HelpAssets.GiveUsACallButton />

                  { modalType === InquiryType.Callback && (
                    <HelpAssets.SendMessageButton
                      onClick={() => {
                        if (!user) {
                          setLoginModalOpen(true)
                        } else {
                          changeModalType(InquiryType.General)
                        }
                      }}
                    />
                  )}
                </div>
              </div>

              <div className="w-full">
                {modalType === InquiryType.Callback && !isSent && (
                  <GetHelpCallbackForm
                    isLoading={!recaptchaLoaded || isInquiryMutationLoading}
                    onSubmit={onSubmit}
                  />
                )}

                {modalType === InquiryType.General && !isSent && (
                  <GetHelpGeneralForm
                    isLoading={!recaptchaLoaded || isInquiryMutationLoading}
                    onSubmit={(details) => createInquiryMutation(details)}
                    onIsOpenChange={setIsChildModalOpen}
                  />
                )}
              </div>
            </div>
          )}

      </FormProvider>

      <CenteredCustomerLoginModal
        isOpen={loginModalOpen}
        onClose={() => setLoginModalOpen(false)}
      />

    </PublicMobileModal>
  )
}

export interface GetHelpFormProps {
  isLoading: boolean
  onSubmit: (data: InquiryDetailsFormFields) => void
}

const SuccessChildren = () => (
  <div className="flex flex-col items-center">
    <CheckCircleIcon className="text-primary text-50" />
    <SectionHeaders.LG className="text-center mt-30 text-grey-750">
      Message Sent
    </SectionHeaders.LG>

    <Paragraphs.XXL className={classNames('mt-15 text-center text-grey-800 max-w-[275px]')}>
      Thanks for reaching out, we will be in touch shortly!
    </Paragraphs.XXL>
  </div>
)

export default GetHelpModal
