import { MouseEvent } from 'react'
import { HeartIcon, WhiteHeartIcon } from 'components/icons'
import classNames from 'classnames'
import { useScreenWidth } from 'data/hooks/useScreenWidth'
import IconButton from './IconButton'

export interface WishlistButtonProps {
  active?: boolean
  visible?: boolean
  variant?: 'whiteHeart' | 'goldHeart' | 'bigProperty' | 'propertyMobileHeart'
  className?: string
  onClick: (e: MouseEvent<HTMLButtonElement>) => void
}

const WishlistButton = ({
  onClick,
  active = false,
  visible,
  variant = 'whiteHeart',
  className,
}: WishlistButtonProps) => {
  const { isMobile } = useScreenWidth(1024)

  const getHeartIcon = () => {
    switch (variant) {
      case 'whiteHeart':
      case 'goldHeart':
        return <HeartIcon />
      case 'propertyMobileHeart':
        return <WhiteHeartIcon className="text-22 stroke-2" />
      case 'bigProperty':
        return (
          <>
            <div className={classNames('hidden px-20 bg-white rounded-full pt-[22px] pb-[21px] h-min text-grey-200 text-[17px] lg:flex', {
              '!text-primary': active,
            })}>
              <HeartIcon />
            </div>
            <HeartIcon className="lg:hidden" />
          </>
        )
    }
  }

  if (variant === 'propertyMobileHeart') {
    return (
      <IconButton
        variant="navbar"
        onClick={onClick}
      >
        {getHeartIcon()}
      </IconButton>
    )
  }

  return (
    <button
      type="button"
      className={classNames(className, {
        // goldHeart || propertyMobileHeart || whiteHeart || bigProperty (mobile)
        'pointer-events-auto w-35 h-35 rounded-full border flex items-center justify-center cursor-pointer transition-all': ['propertyMobileHeart', 'goldHeart', 'whiteHeart'].includes(variant) || (variant === 'bigProperty' && isMobile),
        // goldHeart || propertyMobileHeart
        'text-primary': active && (['propertyMobileHeart', 'goldHeart'].includes(variant)),
        // whiteHeart || bigProperty (mobile)
        'text-white': active && (variant === 'whiteHeart' || (variant === 'bigProperty' && isMobile)),
        // not active and visible goldHeart || whiteHeart
        'text-transparent border-white': ['whiteHeart', 'goldHeart'].includes(variant) && !active && visible,
        //not active and not visible whiteHeart || goldHeart || bigProperty (mobile)
        'text-transparent opacity-0': (['whiteHeart', 'goldHeart'].includes(variant) || (variant === 'bigProperty' && isMobile)) && !active && !visible,
        // bigProperty (mobile)
        'lg:hidden border-white': variant === 'bigProperty' && isMobile,
        // not active and visible bigProperty (mobile)
        'text-transparent': variant === 'bigProperty' && isMobile && !active && visible,
        // bigProperty (not mobile)
        'hidden border border-white rounded-full cursor-pointer pointer-events-auto lg:flex lg:p-3 h-min': variant === 'bigProperty' && !isMobile,

      })}
      onClick={onClick}
    >
      {getHeartIcon()}
    </button>
  )
}
export default WishlistButton
