import React, { useState } from 'react'
import { FacebookIcon, InstagramIcon, PinterestIcon } from './icons'
import PublicNavbarLink from './PublicNavbarLink'
import DestinationsPopover from './popovers/DestinationsPopover'
import LogoLink from './logoLink'
import { setLoginModalOpen } from 'store/auth'
import CustomerProfilemodal from './modals/CustomerProfileModal'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import Link from './Link'

const PublicNavbar = () => {
  const dispatch = useDispatch()
  const [profileModalOpen, setProfileModalOpen] = useState(false)

  const { user, checked } = useSelector(state => ({
    user: state.auth.user,
    checked: state.auth.checked,
  }))

  return (
    <>
      <div className="justify-between hidden w-full mb-16 container-public lg:flex mt-30">
        <div className="flex max-w-[450px] w-full gap-x-10 xl:gap-20 self-end">
          <DestinationsPopover />
          <PublicNavbarLink href="/services">Services & Experiences</PublicNavbarLink>
          <PublicNavbarLink href="/about-us">About</PublicNavbarLink>
        </div>

        <LogoLink
          className="w-full max-w-[191px] h-[105px] lg:h-[121px] lg:max-w-[221px] mb-16"
          variant="tagline"
        />

        <div className="max-w-[450px] w-full">
          <div className="flex flex-col justify-between h-full ">
            <div className="flex justify-end gap-5">
              <Link href="/contact-us">
                <div className="px-16 h-[28px] py-2 bg-grey-250 bg-opacity-30 rounded-[13px] cursor-pointer hover:bg-white">
                  <a className="text-[13px] text-grey-800 font-normal">Contact</a>
                </div>
              </Link>
              <>
                {
                  checked && user !== null
                    ? <button type="button"
                      className="px-16 py-2 h-[28px] bg-grey-250 bg-opacity-30 rounded-[13px] cursor-pointer hover:bg-white"
                      onClick={() => setProfileModalOpen(true)}
                    >
                      <a className="text-[13px] text-grey-800 font-normal">{user.first_name}</a>
                    </button>
                    : <button type="button"
                      className="px-16 py-2 h-[28px] bg-grey-250 bg-opacity-30 rounded-[13px] cursor-pointer hover:bg-white"
                      onClick={() => dispatch(setLoginModalOpen(true))}
                    >
                      <a className="text-[13px] text-grey-800 font-normal">Log In</a>
                    </button>
                }
              </>
            </div>

            <div className="flex items-center justify-end gap-25">
              <div className="flex gap-10 text-20">
                <a href="https://facebook.com/amaselections" className="text-grey-800" target="_blank" rel="noreferrer">
                  <FacebookIcon />
                  <div className="sr-only">Facebook</div>
                </a>
                <a href="https://instagram.com/ama_selections" className="text-grey-800" target="_blank" rel="noreferrer">
                  <InstagramIcon />
                  <div className="sr-only">Instagram</div>
                </a>
                <a href="https://pinterest.com/amaselections" className="text-grey-800" target="_blank" rel="noreferrer">
                  <PinterestIcon />
                  <div className="sr-only">Pinterest</div>
                </a>
              </div>
              <PublicNavbarLink href="/magazine">Magazine</PublicNavbarLink>
            </div>
          </div>
        </div>
      </div>

      <CustomerProfilemodal isOpen={profileModalOpen} onClose={() => setProfileModalOpen(false)} />
    </>
  )
}

export default PublicNavbar
