import { useMemo, useState } from 'react'
import { formatEnum } from 'data/helpers/strings'
import { formatDateReadable, parseDate } from 'data/helpers/dates'
import { TripScheduleGroup, TripScheduleService } from 'gql/graphql'
import ServiceCard from 'components/home/dashboard/ServiceCard'
import { ClockOutlineIcon, ExclaimCircleIcon } from 'components/icons'
import { getFormattedTime } from 'data/helpers/survey/tripSchedule'
import Paragraphs from 'components/home/common/typography/Paragraphs'
import { ModalProps } from 'data/types/modal'
import PublicMobileModal from 'components/PublicMobileModal'
import Headers from 'components/home/common/typography/Headers'
import { groupBy } from 'lodash'
import CardSlider from 'components/home/CardSlider'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import UserTripServiceModal, { UserTripServiceModalProps } from 'components/booking/trip-schedule/UserTripServiceModal'
dayjs.extend(advancedFormat)

interface ConnectedServicesModalProps extends ModalProps {
  tripGroup: Pick<TripScheduleGroup, 'id' | 'title' | 'subtitle' | 'status'> & {
    main_image?: Pick<NonNullable<TripScheduleGroup['main_image']>, 'url'> | null
    days?: Pick<TripScheduleService, 'id' | 'date' | 'start_time' | 'end_time'>[] | null
  }
}

const ConnectedServicesModal = ({
  tripGroup,
  isOpen,
  onClose,
}: ConnectedServicesModalProps) => {
  const [serviceModal, setServiceModal] = useState<Pick<UserTripServiceModalProps, 'isOpen' | 'serviceId'>>({ isOpen: false })

  const servicesGroupedByDay = useMemo(
    () => groupBy(tripGroup.days, 'date'),
    [tripGroup],
  )

  return (
    <PublicMobileModal
      isOpen={isOpen}
      onClose={onClose}
      className={{
        panel: '!max-w-[600px] flex flex-col gap-20 pb-18',
      }}
      isChildModalOpen={serviceModal.isOpen}
    >
      <div className="sticky top-0 z-10 flex flex-col w-full pb-5 bg-white pt-18">
        <Headers.H1 Tag="h3" className="text-center leading-28">
          Connected Services
        </Headers.H1>
      </div>

      {
        Object.entries(servicesGroupedByDay).map(([key, days]) => (
          <>
            <Paragraphs.MD
              key={`key-${key}`}
              className="mt-5 font-bold text-center uppercase text-grey-650"
            >
              {formatDateReadable(
                key,
                {
                  withDay: true,
                  dayFormat: 'dddd',
                },
              )}
            </Paragraphs.MD>

            <CardSlider
              key={`days-${key}`}
              cards={days.map((tripDay, index) => ({
                className: 'max-w-max bg-grey-150 p-12 rounded-16',
                render: () => (
                  <ServiceCard
                    key={`from-your-concierge-${index}`}
                    superHeading={parseDate(key)?.format('MMMM Do')}
                    heading={tripGroup.title}
                    subheading={tripGroup.subtitle}
                    onClick={() => setServiceModal({
                      isOpen: true,
                      serviceId: tripDay.id,
                    })}
                    media={{
                      src: tripGroup.main_image?.url,
                      alt: tripGroup.title,
                      size: 'min-w-[55px] max-w-[55px]',
                    }}
                    iconText={[
                      {
                        icon: <ClockOutlineIcon />,
                        text: getFormattedTime(
                          tripDay?.start_time,
                          tripDay?.end_time,
                        ),
                      },
                    ]}
                    status={{
                      icon: <ExclaimCircleIcon />,
                      text: formatEnum(tripGroup.status),
                      className: 'text-red-800',
                    }}
                  />
                ),
              }))}
            />
          </>
        ))
      }

      <UserTripServiceModal
        {...serviceModal}
        onClose={() => setServiceModal({ isOpen: false })}
      />
    </PublicMobileModal>
  )
}

export default ConnectedServicesModal
