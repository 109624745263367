import React, { forwardRef } from 'react'
import { useState } from 'react'
import { EyeIcon } from 'components/icons'
import classNames from 'classnames'
import { ReactChild } from 'data/types/types'

export interface InputPropTypes extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'dangerouslySetInnerHTML' | 'children' | 'prefix' | 'style' | 'className'> {
  error?: string
  label?: string
  prefix?: ReactChild
  suffix?: ReactChild
  variant?: 'grey' | 'inline-right' | 'searchbar'
  capitalise?: boolean
  withRing?: boolean
  preventDecimals?: boolean
  className?: {
    wrapper?: string
    container?: string
  }
}

const Input = forwardRef<HTMLInputElement, InputPropTypes>(function input ({
  type = 'text',
  className,
  onChange,
  onKeyUp,
  error,
  name,
  label,
  prefix,
  suffix,
  placeholder,
  value,
  defaultValue,
  step,
  variant,
  capitalise = false,
  withRing = true,
  disabled = false,
  readOnly = false,
  preventDecimals = false,
  ...props
}, ref) {
  const [showPassword, setShowPassword] = useState(false)

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (preventDecimals) {
      // Only numbers
      if (e.charCode >= 48 && e.charCode <= 57) {
        return
      } else {
        e.preventDefault()
      }
    }
  }

  return (
    <div className={classNames(className?.wrapper, 'w-full')}>
      <div className={classNames(
        className?.container,
        'w-full h-[56px] text-16 flex items-center relative rounded-16',
        {
          '!ring-0 !outline-none': withRing && variant === 'searchbar',
          'ring-1 focus-within:ring-opacity-50': withRing,
          'focus-within:ring-primary ring-opacity-100': withRing && !error,
          'ring-red ring-opacity-50': withRing && error,

          'outline-none !ring-0': !withRing,
        },
        {
          'bg-grey-100 ring-grey-100': variant === 'grey',
          'bg-transparent !h-full font-poppins !rounded-none': variant === 'inline-right',
          'bg-grey-150': variant === 'searchbar',
        },
      )}>
        {
          prefix
            ? <div className={classNames(
              'absolute bottom-[7px] z-[1] px-20',
              {
                '!text-red !opacity-100': error,
                'text-primary-fresh h-full flex items-center !bottom-0': variant === 'searchbar',
                'text-grey-500': !error && !value,
                'text-grey-800': !error && value,
              },
            )}>
              {prefix}
            </div>
            : null
        }
        <input
          ref={ref}
          type={type === 'password' ? showPassword ? 'text' : 'password' : type}
          className={classNames(
            'absolute h-full inset-0 text-grey-800 placeholder-grey-500 text-14 lg:text-15 w-full border-none bg-transparent px-20 transition rounded-16',
            {
              'bg-grey-100': variant === 'grey',
              '!px-0 !py-0 text-right rounded-none !text-13 placeholder:text-grey-300': variant === 'inline-right',
              'text-15': variant === 'searchbar',

              'focus:outline-none focus:ring-transparent': withRing,
              'outline-none !ring-0': !withRing,

              'pl-35': prefix,
              'pl-50': prefix && variant === 'searchbar',
              'pt-26': label,
              'text-red': error,
              'capitalize': capitalise,

              '!bg-grey-100': disabled,
            },
          )}
          name={name}
          onChange={onChange}
          onKeyUp={onKeyUp}
          onKeyPress={handleKeyPress}
          placeholder={placeholder}
          value={value}
          defaultValue={defaultValue}
          step={step}
          disabled={disabled}
          readOnly={readOnly}
          {...props}
        />

        {
          type === 'password'
            ? <button
              aria-label="Show password"
              type="button"
              className="absolute top-0 bottom-0 right-0 flex items-center px-20 cursor-pointer rounded-16"
              onClick={() => setShowPassword(!showPassword)}
            >
              <EyeIcon />
            </button>
            : null
        }

        {
          suffix
            ? <div className="absolute top-0 bottom-0 right-0 flex items-center px-20 text-grey-800">
              {suffix}
            </div>
            : null
        }

        <div className="absolute inset-0 flex items-center px-20 pointer-events-none">
          <span className="text-opacity-50 transition-all transform -translate-y-10 text-12 text-grey-900">
            {label}
          </span>
        </div>
      </div>

      {
        error
          ? <p className="text-red text-[12px] font-bold pl-4 pt-2">{error}</p>
          : null
      }
    </div>
  )
})

export default Input
