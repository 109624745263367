import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { NavSection, NavSectionProps } from 'components/user/profile/NavSection'
import { NavItem, NavItemProps } from 'components/user/profile/NavItem'
import {
  ChevronRightFlaredIcon,
  ProfileBellIcon,
  ProfileEmailIcon,
  ProfileFlagIcon,
  ProfileInviteIcon,
  ProfileLogoutIcon,
  ProfilePageIcon,
  ProfilePenIcon,
  ProfilePhoneIcon,
  WhiteHeartIcon,
} from 'components/icons'
import useAuth from 'data/hooks/User/useAuth'
import InviteFriendsModal from 'components/InviteFriendsModal'
import { useUnreadCount } from 'data/hooks/useUnreadCount'
import ApiClient from 'data/api/api_client'
import ProfileImage from 'components/ProfileImage'
import EditCustomerProfileModal from 'components/user/profile/modals/EditCustomerProfileModal'
import { Membership } from 'data/enums/membership-type'
import useNotificationPreferences, { NotificationPreferenceType } from 'data/hooks/User/useNotificationPreferences'
import { useMobileApp } from 'data/hooks/MobileApp/useMobileApp'
import CustomerGetInTouchModal from 'components/modals/getHelp/CustomerGetInTouchModal'
import Button from 'components/Button'
import { useDispatch } from 'react-redux'
import { setLoginModalOpen, setRegisterModalOpen } from 'store/auth'
import CustomerMembershipModal from './modals/CustomerMembershipModal'

interface ProfileModalNavigationProps {
  onClose: () => void
  onIsChildModalOpenChange?: (isOpen: boolean) => void
}

enum ProfileSubModal {
  EditModal,
  InviteFriends,
  GetHelp,
  Membership,
}

export const ProfileModalNavigation = ({
  onClose,
  onIsChildModalOpenChange,
}: ProfileModalNavigationProps) => {
  const { user, logout } = useAuth()
  const { isUpdating: isEmailUpdating, toggle: toggleEmail } = useNotificationPreferences(NotificationPreferenceType.Email)
  const { isUpdating: isPushUpdating, toggle: togglePush } = useNotificationPreferences(NotificationPreferenceType.Push)
  const dispatch = useDispatch()

  const { isMobileApp } = useMobileApp()
  const { unreadNotificationCount } = useUnreadCount()

  const logoutUser = () => {
    ApiClient.auth.logout().then(() => {
      logout()
      onClose()
    })
  }

  const [modalState, setModalState] = useState<{
    option: ProfileSubModal
  } | undefined>(undefined)

  useEffect(() => {
    onIsChildModalOpenChange?.(!!modalState)
  }, [modalState?.option])

  const NavItems: {
    heading?: NavSectionProps['heading']
    items: NavItemProps[]
  }[] = [
    {
      items: [
        {
          children: 'Notifications',
          icon: <ProfileBellIcon className="text-22" />,
          href: '/home/notifications',
          number: unreadNotificationCount,
        },
        {
          children: 'Wishlists',
          icon: <WhiteHeartIcon className="text-[21px] stroke-[1.5]" />,
          href: '/home/wishlists',
        },
        {
          children: 'Magazine',
          icon: <ProfilePageIcon className="-ml-2 text-24" />,
          href: '/magazine',
        },
      ],
    },
    {
      heading: 'Account & Settings',
      items: [
        {
          children: <>
            Membership Status: <span className="ml-5 capitalize text-primary-fresh">{user?.membership_type ?? Membership.REGISTERED}</span>
          </>,
          icon: <ProfilePenIcon className="text-22" />,
          onClick: () => setModalState({ option: ProfileSubModal.Membership }),
        },
        {
          children: 'Invite friends & Earn travel credit',
          icon: <ProfileInviteIcon className="text-[25px]" />,
          onClick: () => setModalState({ option: ProfileSubModal.InviteFriends }),
        },
      ],
    },
    {
      heading: 'System Notifications',
      items: [
        {
          children: 'Email',
          icon: <ProfileEmailIcon className="text-22" />,
          toggle: {
            checked: user?.via_email ?? false,
            onChange: toggleEmail,
            isLoading: isEmailUpdating,
          },
        },
        {
          children: 'Push',
          icon: <ProfileFlagIcon className="text-22" />,
          toggle: {
            checked: user?.via_push ?? false,
            onChange: togglePush,
            isLoading: isPushUpdating,
            disabled: !isMobileApp,
          },
        },
      ],
    },
    {
      heading: 'Help & Other',
      items: [
        {
          children: 'Contact Us',
          icon: <ProfilePhoneIcon className="text-[21px]" />,
          onClick: () => setModalState({ option: ProfileSubModal.GetHelp }),
        },
        {
          children: 'Terms of service',
          icon: <ProfilePageIcon className="-ml-2 text-24" />,
          href: '/legal-notice',
        },
        {
          children: 'Privacy Policy',
          icon: <ProfilePageIcon className="-ml-2 text-24" />,
          href: '/privacy-policy',
        },
        {
          children: 'Logout',
          icon: <ProfileLogoutIcon className="text-[23px]" />,
          onClick: logoutUser,
        },
      ],
    },
  ]

  const UnAuthenticatedNavItems: {
    heading?: NavSectionProps['heading']
    items: NavItemProps[]
  }[] = [
    {
      heading: 'Help & Other',
      items: [
        {
          children: 'Contact Us',
          icon: <ProfilePhoneIcon className="text-[21px]" />,
          onClick: () => setModalState({ option: ProfileSubModal.GetHelp }),
        },
        {
          children: 'Terms of service',
          icon: <ProfilePageIcon className="-ml-2 text-24" />,
          href: '/legal-notice',
        },
        {
          children: 'Privacy Policy',
          icon: <ProfilePageIcon className="-ml-2 text-24" />,
          href: '/privacy-policy',
        },
      ],
    },
  ]

  return (
    <div className="flex flex-col h-full">
      {
        user
          ? (
            <>
              <button
                type="button"
                onClick={() => setModalState({ option: ProfileSubModal.EditModal })}
                className={classNames(
                  'flex items-center mx-10 px-12 text-left mt-26 py-8 -mb-4 text-grey-500',
                  'focus:outline-none focus:text-primary-fresh',
                  'hover:bg-grey-100 rounded-16',
                )}
                sr-only="Show profile"
              >
                <ProfileImage
                  user={user}
                  className="min-w-[40px] max-w-[40px] min-h-[40px] max-h-[40px]"
                />

                <div className="flex flex-col w-full ml-14 tracking-1/2">
                  <h3 className="text-blue-1000 text-[17px] leading-24">
                    {user?.first_name ?? ''}
                  </h3>

                  <p className="text-12 leading-18">
                    Show profile
                  </p>
                </div>

                <ChevronRightFlaredIcon className="stroke-2 text-24" />
              </button>

              {
                NavItems.map(({ items, heading }, index) => (
                  <>
                    <Divider
                      key={`${index}-${heading}-divider`}
                      className={classNames({
                        '!mb-12': !heading,
                      })}
                    />

                    <NavSection
                      key={`${index}-${heading}-section`}
                      heading={heading}
                      className={{
                        container: classNames({
                          'gap-y-[6px]': !heading,
                        }),
                      }}
                    >
                      {
                        items.map(({ children, ...itemProps }, index) => (
                          <NavItem
                            key={`${index}-${heading}-item-${index}`}
                            {...itemProps}
                          >
                            {children}
                          </NavItem>
                        ))}
                    </NavSection>
                  </>
                ))
              }
            </>
          )
          : (
            UnAuthenticatedNavItems.map(({ items, heading }, index) => (
              <>
                <Divider
                  key={`${index}-${heading}-divider`}
                  className={classNames({
                    '!mb-12': !heading,
                  })}
                />

                <NavSection
                  key={`${index}-${heading}-section`}
                  heading={heading}
                  className={{
                    container: classNames({
                      'gap-y-[6px]': !heading,
                    }),
                  }}
                >
                  {
                    items.map(({ children, ...itemProps }, index) => (
                      <NavItem
                        key={`${index}-${heading}-item-${index}`}
                        {...itemProps}
                      >
                        {children}
                      </NavItem>
                    ))}
                </NavSection>


                <Button
                  onClick={() => dispatch(setLoginModalOpen(true))}
                  className="mt-auto mb-2 mx-20 !rounded-16"
                  variant="primary"
                >
                  Sign in
                </Button>
                <div className="mt-10 text-center text-14 text-grey-500">
                  Don&apos;t have an account yet? <span className="underline text-primary" onClick={() => dispatch(setRegisterModalOpen(true))}>Sign up now</span>
                </div>
              </>
            ))
          )

      }

      <EditCustomerProfileModal
        isOpen={modalState?.option === ProfileSubModal.EditModal}
        onClose={() => setModalState(undefined)}
      />

      <InviteFriendsModal
        isOpen={modalState?.option === ProfileSubModal.InviteFriends}
        onClose={() => setModalState(undefined)}
      />

      <CustomerGetInTouchModal
        isOpen={modalState?.option === ProfileSubModal.GetHelp}
        onClose={() => setModalState(undefined)}
      />

      <CustomerMembershipModal
        isOpen={modalState?.option === ProfileSubModal.Membership}
        onClose={() => setModalState(undefined)}
      />
    </div >
  )
}

interface DividerProps {
  className?: string
}

const Divider = ({ className }: DividerProps) => (
  <hr className={classNames(
    'mx-20 mt-12 mb-25 text-grey-250',
    className,
  )} />
)
