import React, { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import Form from 'components/form'
import Button from 'components/Button'
import ApiClient from 'data/api/api_client'
import PropTypes from 'prop-types'
import Notification from 'components/Notification'
import { useForm } from 'react-hook-form'
import { useNotifier } from 'react-headless-notifier'
import { useDispatch, useSelector } from 'react-redux'
import preferencesOne from 'assets/images/preferences-one.jpg'
import preferencesTwo from 'assets/images/preferences-two.jpg'
import preferencesThree from 'assets/images/preferences-three.jpg'
import { ArrowLeftIcon, CheckCircleIcon } from 'components/icons'
import router from 'next/router'
import SuccessModalDrawer from 'components/modals/SuccessModalDrawer'
import SuccessModal from 'components/modals/SuccessModal'
import { Membership } from 'data/enums/membership-type'
import { setTier } from 'store/preferences'
import Image from 'components/Image'

const PreferencesSection = ({ onFinish = () => router.push('/'), isDrawer = false }) => {
  const [isSuccess, setIsSuccess] = useState(false)
  const [position, setPosition] = useState(0)

  const { notify } = useNotifier()
  const dispatch = useDispatch()

  const { user, selectedMembershipTier } = useSelector(state => ({
    user: state.auth.user,
    selectedMembershipTier: state.preferences.tier,
  }))

  const updatePosition = (newIndex) => {
    if (newIndex >= 0 && newIndex < questions.length) {
      setPosition(newIndex)
    }
  }

  const { register, handleSubmit, getValues } = useForm({
    defaultValues: user?.preferences.reduce((parent, preference) => {
      parent[preference] = Number(preference)
      return parent
    }, {}),
  })

  const preferencesMutation = useMutation(
    (details) => {
      let answers = Object.values(details).filter(item => !!item)
      ApiClient.preferences.storeAnswers({ answers: answers }, user)
    },
    {
      onSuccess: () => {
        if (selectedMembershipTier && selectedMembershipTier !== Membership.REGISTERED) {
          updateMembership.mutate(selectedMembershipTier)
        }
        setIsSuccess(true)
      },
      onError: () => {
        notify(
          <Notification message="There was an error updating your preferences" type="error" />,
        )
      },
    },
  )

  const updateMembership = useMutation(
    (tier) => ApiClient.users.updateMembership(user, { tier: tier }),
    {
      onSuccess: (data) => {
        if (data?.redirect_url) {
          dispatch(setTier(null))
          return window.open(data.redirect_url, '_self')
        }

        throw new Error()
      },
      onError: () => {
        notify(
          <Notification message="There was an error updating your preferences" type="error" />,
        )
      },
    },
  )

  const { data: questions } = useQuery('questions', () => ApiClient.preferences.getPreferences())

  return (
    <>
      <div className="relative flex flex-col-reverse h-full overflow-y-auto lg:gap-20 xl:-mt-40 lg:ml-20 xl:ml-100 xl:mr-0 lg:flex-row xl:gap-100 xl:pb-80">
        <div className="overflow-y-auto lg:overflow-y-visible flex flex-col w-full h-full max-h-[calc(100%-250px)] lg:max-h-max lg:basis-3/5 xl:basis-1/2 px-25 lg:px-0 lg:pb-50 lg:h-auto py-40 xl:py-0">
          <h3 className="hidden font-serif font-bold text-grey-900 text-32 xl:text-48 lg:block">
            Let us know your preferences
          </h3>

          <p className="hidden mt-25 text-grey-800 text-16 lg:block">
            We will ask you {questions?.length ?? 0} questions in order for us to provide you with a more tailored experience; this will only take 2 minutes!
          </p>

          {questions
            && <form
              className="flex flex-col-reverse lg:mt-70 lg:flex-col gap-50"
              onSubmit={handleSubmit(preferencesMutation.mutate)}
            >
              <div className="flex flex-col justify-between h-full gap-40">
                <div className="flex flex-col gap-25">
                  <h5 className="font-serif font-bold text-grey-900 text-28 mb-15">
                    {position + 1}. {questions[position].question}
                  </h5>

                  {questions[position]?.answers
                    && questions[position].answers.map((answer) => (
                      <Form.Checkbox
                        key={answer.id}
                        className="text-grey-800 text-16 min-w-[18px]"
                        style="preferences"
                        useValue={true}
                        value={answer.id}
                        checked={getValues(`${answer.id}`) == answer.id}
                        defaultChecked={user?.preferences.includes(answer.id)}
                        {...register(`${answer.id}`)}
                      >
                        {answer.answer}
                      </Form.Checkbox>
                    ))}
                </div>

                <div className="flex items-center justify-center lg:justify-between justify-self-end">
                  <p
                    className={`font-bold text-grey-800 text-14 underline ${position === 0 ? 'opacity-50 pointer-events-none' : 'cursor-pointer'} hidden lg:block`}
                    onClick={() => updatePosition(position - 1)}
                  >
                    Go Back
                  </p>

                  {position === questions?.length - 1
                    ? <div>
                      <Button className="w-[160px]" type="submit">
                        Finish
                      </Button>
                    </div>
                    : <Button className="w-[160px]" onClick={() => updatePosition(position + 1)}>
                      Next
                    </Button>
                  }
                </div>
              </div>

              <div className="flex justify-center gap-8 ">
                {questions.map((item, index) => (
                  <div
                    key={index}
                    className={`rounded-full w-8 h-8 ${position === index ? 'bg-grey-1000' : 'bg-grey-300'}`}
                  />
                ))}
              </div>
            </form>
          }
        </div>

        <div className="relative w-full ml-auto h-[250px] lg:h-full lg:max-h-full lg:basis-2/5 lg:w-[40%] xl:fixed xl:top-[77px] xl:right-0 xl:bottom-0">
          <Image
            src={
              position === 5
                ? preferencesThree
                : position > 2
                  ? preferencesTwo
                  : preferencesOne
            }
            className="object-cover"
            loading="lazy"
            layout="fill"
          />
          <h5 className="absolute w-full font-serif font-bold text-center text-white text-38 drop-shadow-title top-110 lg:hidden">
            {position === 5
              ? <span>Food<br />&amp; Drink</span>
              : position > 2
                ? <span>Services<br />&amp; Experiences</span>
                : <span>Properties<br />&amp; Destinations</span>
            }
          </h5>
        </div>

        {!isDrawer
          && <button
            type="button"
            className={'absolute lg:hidden text-white top-20 left-20'}
            onClick={() => updatePosition(position - 1)}
          >
            <ArrowLeftIcon />
          </button>
        }

        <SuccessModalDrawer
          isOpen={isDrawer ? isSuccess : false}
          onClose={() => {
            setIsSuccess(false)
            onFinish()
          }}
          icon={<CheckCircleIcon />}
          title="Profile Updated"
          description="Thank you for updating your preferences!"
        />

        <SuccessModal
          isOpen={!isDrawer ? isSuccess : false}
          onClose={() => {
            setIsSuccess(false)
            onFinish()
          }}
          icon={<CheckCircleIcon />}
          title="Profile Updated"
          description="Thank you for updating your preferences!"
        />

      </div>

      {isDrawer
        && <button
          type="button"
          className={'absolute lg:hidden text-white top-20 left-20'}
          onClick={() => updatePosition(position - 1)}
        >
          <ArrowLeftIcon />
        </button>
      }
    </>
  )
}

PreferencesSection.propTypes = {
  onFinish: PropTypes.func,
  isDrawer: PropTypes.bool,
}

export default PreferencesSection
