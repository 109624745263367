import React from 'react'
import PropTypes from 'prop-types'
import PublicModalDrawer from 'components/PublicModalDrawer'
import StatusPanel from 'components/StatusPanel'

const SuccessModalDrawer = ({
  isOpen,
  onClose = () => { },
  icon,
  title,
  description,
}) => {
  return (
    <PublicModalDrawer isOpen={isOpen} onClose={onClose} className="pt-30">
      <div className="flex flex-col items-center h-full">
        <div className="my-auto pb-50">
          <StatusPanel
            icon={icon}
            title={title}
            description={description}
          />
        </div>
      </div>
    </PublicModalDrawer>
  )
}

SuccessModalDrawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  icon: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
}

export default SuccessModalDrawer
