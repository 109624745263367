import { ArrowRightIcon, LoadingIcon } from './icons'
import classNames from 'classnames'
import { ReactChild } from 'data/types/types'
import { RequireOnlyOne } from 'data/helpers/types/requireOnlyOne'
import { AnchorHTMLAttributes } from 'react'
import Link from './Link'

type PropTypes = {
  onClick?: () => void
  href?: string
  children: ReactChild
  className?: string
  icon?: ReactChild
  about?: string
  variant?: 'outline' | 'primary' | 'admin-primary'
  buttonType?: 'button' | 'submit'
  isLoading?: boolean
  disabled?: boolean
  target?: AnchorHTMLAttributes<HTMLAnchorElement>['target']
}

const ReadMoreButton = ({
  onClick,
  href,
  children,
  className = '',
  icon = <ArrowRightIcon />,
  about,
  variant = 'outline',
  buttonType,
  isLoading,
  disabled,
  target,
}: RequireOnlyOne<PropTypes, 'href' | 'onClick' | 'buttonType' >) => {
  const variantClassnames = classNames(
    'flex items-center gap-16 px-[28px] tracking-1/2 leading-20 focus:outline-none focus:ring-3',
    className,
    {
      'border border-black border-opacity-30 rounded-4 hover:bg-primary hover:text-white hover:border-primary text-grey-900 ring-grey-200': variant === 'outline',
      'border border-primary rounded-4 bg-primary hover:bg-primary-dark hover:border-primary-dark text-white ring-primary ring-opacity-30': variant === 'primary' || variant === 'admin-primary',

      'py-18 text-14 font-bold uppercase': variant !== 'admin-primary',
      'py-14 text-16 font-normal capitalize ': variant === 'admin-primary',
      'opacity-50 pointer-events-none': disabled,
    },
  )

  if (onClick !== undefined || buttonType) {
    return <button
      disabled={disabled}
      onClick={onClick}
      className={variantClassnames}
      type={buttonType ?? 'button'}
    >
      {
        isLoading
          ? <LoadingIcon className="h-20" />
          : <>
            {icon}
            <span>{children}</span>
          </>
      }
    </button>
  }

  return (
    <Link href={href} about={about} aria-disabled={disabled}>
      <a className={variantClassnames} target={target}>
        {icon}
        <span>{children}</span>
      </a>
    </Link>
  )
}

export default ReadMoreButton
