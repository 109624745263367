import Button from 'components/home/common/Button'
import { AddIcon } from 'components/icons'
import ServiceSpecificSearchModal from 'components/modals/home/search/services/ServiceSpecificSearchModal'
import React, { useState } from 'react'

interface BookNewTripServiceTriggerProps {
  bookingId: string
}

const BookNewTripServiceTrigger = ({
  bookingId,
}: BookNewTripServiceTriggerProps) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <div className="fixed bottom-safe-offset-30 mb-40 lg:mt-0 mt-auto lg:bottom-[44px] px-24 lg:px-0 z-10 flex justify-end w-full lg:justify-center pointer-events-none">
        <Button
          className={{
            button: '!rounded-full !p-16 pointer-events-auto',
          }}
          onClick={() => setIsOpen(true)}
        >
          <AddIcon className="w-16 h-16" />
        </Button>

        <ServiceSpecificSearchModal
          bookingId={bookingId}
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          className={{
            panel: 'max-w-[850px]',
          }}
        />
      </div>
    </>
  )
}

export default BookNewTripServiceTrigger
