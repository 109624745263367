import { Membership } from './enums/membership-type'

export const config = {
  baseURL: process.env.NEXT_PUBLIC_API_BASE_URL!,
  appURL: process.env.NEXT_PUBLIC_APP_BASE_URL!,
  graphQlURL: process.env.NEXT_PUBLIC_GRAPHQL_BASE_URL!,

  isMobileAppCookie: process.env.NEXT_PUBLIC_MOBILE_APP_COOKIE!,
  awsBucket: process.env.AWS_BUCKET!,

  enableImageLoader: process.env.NEXT_PUBLIC_ENABLE_IMAGE_LOADER === 'true',

  displayAppURL: 'amaselections.com',
  whitelabelURL: process.env.NEXT_PUBLIC_WHITELABEL_BASE_URL!,
  googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAP_API_KEY,
  amaSelectionsPhoneNumber: process.env.NEXT_PUBLIC_AMA_SELECTIONS_PHONE_NUMBER?.replaceAll(' ', ''),
  amaSelectionsDisplayPhoneNumber: process.env.NEXT_PUBLIC_AMA_SELECTIONS_PHONE_NUMBER,
  amaSelectionsEmail: process.env.NEXT_PUBLIC_AMA_SELECTIONS_EMAIL,
  partnerInquiryFormLink: process.env.NEXT_PUBLIC_PARTNER_INQUIRIES_LINK!,
  travelAgentInquiryFormLink: process.env.NEXT_PUBLIC_TRAVEL_AGENT_INQUIRIES_LINK!,
  bankName: process.env.NEXT_PUBLIC_BANK_NAME!,
  bankAccountName: process.env.NEXT_PUBLIC_ACCOUNT_NAME!,
  bankIban: process.env.NEXT_PUBLIC_IBAN!,
  bankBic: process.env.NEXT_PUBLIC_BIC!,

  stripePercentageCharge: process.env.NEXT_PUBLIC_STRIPE_PERCENTAGE_CHARGE!,
  stripeDashboardUrl: process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY!.includes('test')
    ? 'https://dashboard.stripe.com/test'
    : 'https://dashboard.stripe.com',
  stripePassportUploadDays: 7,

  bankAddressLine1: process.env.NEXT_PUBLIC_BANK_ADDRESS_LINE_1!,
  bankAddressLine2: process.env.NEXT_PUBLIC_BANK_ADDRESS_LINE_2!,
  bankAddressLine3: process.env.NEXT_PUBLIC_BANK_ADDRESS_LINE_3!,
  stripePK: process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY!,
  recaptchaSiteKey: process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY,
  siteName: 'A.M.A Selections Holiday Villas',
  acceptedImageTypes: 'image/jpg,image/jpeg,image/bmp,image/png,image/webp',
  acceptedFileTypes: 'application/pdf,image/jpeg,image/png,image/webp,video/mp4,application/vnd.openxmlformats-officedocument.wordprocessingml.document,doc,docx,application/applefil',
  acceptedInvoiceFileTypes: 'application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,doc,docx,application/applefil',
  wishlistCharacterLimit: 50,
  wishlistCreationLimit: 50,
  homepageVideoHLSUrl: process.env.NEXT_PUBLIC_HOMEPAGE_VIDEO_URL
    ? process.env.NEXT_PUBLIC_HOMEPAGE_VIDEO_URL + '/manifest/video.m3u8'
    : null,
  homepageVideoDashUrl: process.env.NEXT_PUBLIC_HOMEPAGE_VIDEO_URL
    ? process.env.NEXT_PUBLIC_HOMEPAGE_VIDEO_URL + '/manifest/video.mpd'
    : null,
  homepageVideoThumbnailURL: process.env.NEXT_PUBLIC_HOMEPAGE_VIDEO_URL
    ? process.env.NEXT_PUBLIC_HOMEPAGE_VIDEO_URL + '/thumbnails/thumbnail.jpg?height=1400'
    : null,
  homepageVideoId: process.env.NEXT_PUBLIC_HOMEPAGE_VIDEO_ID,

  // Commission
  vatPercentage: 20,
  totalCommissionPercentageThreshold: 20,
  maximumAgentCommission: 10,

  membership: {
    [Membership.REGISTERED]: {
      features: [],
    },
    [Membership.CLASSIC]: {
      features: [
        'Access to exclusive offers',
        '1% loyalty credit earned on every stay',
      ],
    },
    [Membership.SILVER]: {
      description: 'Never pay for service fees again.<br />1% Loyalty credit for your next booking.',
      yearlyPrice: 500,
      features: [
        'Access to exclusive offers',
        '1% loyalty credit earned on every stay',
        'Up to 10 reservations per booking (250€ value)',
        'Zero concierge service fees on all services booked',
      ],
    },
    [Membership.GOLD]: {
      description: 'Never pay for service fees again.<br />2% Loyalty credit for your next booking.<br />10 Reservations included on all bookings.<br />1500€ Accidental damage waiver included.',
      yearlyPrice: 1000,
      features: [
        'Access to exclusive offers',
        '2% loyalty credit earned on every stay',
        'Up to 10 reservations per booking (250€ value)',
        'Zero concierge service fees on all services booked',
        '1500€ in accidental damage waiver included for all bookings',
      ],
    },
  },

  services: {
    service: {
      id: 1,
      searchTerm: 'Service',
    },
    experience: {
      id: 2,
      searchTerm: 'Experience',
    },
  },
}
