import PublicMobileModalDrawer from 'components/PublicMobileModalDrawer'
import useAuth from 'data/hooks/User/useAuth'
import { ModalProps } from 'data/types/modal'
import { EditItem, EditItemProps } from '../items/EditItem'
import { parseDate } from '@ama-selections/ui'
import Link from 'components/Link'
import { EditProfileImage } from '../items/EditProfileImage'
import IconButton from 'components/IconButton'
import { ArrowLeftIcon } from 'components/icons'

interface EditCustomerProfileModalProps extends ModalProps {}

export enum EditCustomerProfileSection {
  ProfileImage = 'profile_image',
  FirstName = 'first_name',
  LastName = 'last_name',
  Phone = 'phone',
  Email = 'email',
  DOB = 'date_of_birth',
  Address = 'address',
  Passport = 'passport',
}

const EditCustomerProfileModal = ({
  isOpen,
  onClose,
}: EditCustomerProfileModalProps) => {
  const { user } = useAuth()

  const EditItems: EditItemProps[] = [
    {
      field: EditCustomerProfileSection.FirstName,
      value: user?.first_name ?? '',
    },
    {
      field: EditCustomerProfileSection.LastName,
      value: user?.last_name ?? '',
    },
    {
      field: EditCustomerProfileSection.Phone,
      value: `(+${user?.phone_country_code}) ${user?.phone}`,
    },
    {
      field: EditCustomerProfileSection.Email,
      value: user?.email ?? '',
    },
    {
      field: EditCustomerProfileSection.DOB,
      value: user?.date_of_birth
        ? parseDate(user?.date_of_birth)?.format('DD MMMM YYYY')
        : 'No date of birth provided',
    },
    {
      field: EditCustomerProfileSection.Address,
      value: user?.address?.single_line ?? 'No address provided',
    },
    {
      field: EditCustomerProfileSection.Passport,
      value: user?.passport_image?.url
        ? (
          <Link
            href={user?.passport_image.url}
            target="_blank"
            onClick={(e) => e.stopPropagation()}
          >
            {user?.passport_image.file_name}
          </Link>
        )
        : 'No passport uploaded',
    },
  ]

  return (
    <PublicMobileModalDrawer
      isOpen={isOpen}
      onClose={onClose}
      position="right"
      hasFocusTrap
      title="Profile"
      className={{
        panel: 'flex flex-col overflow-y-auto pb-safe-offset-10 px-20',
      }}
    >
      <IconButton
        className="absolute top-safe-offset-20 left-20"
        onClick={onClose}
        variant="modal-close"
      >
        <ArrowLeftIcon className="stroke-2" />
      </IconButton>

      <EditProfileImage />

      <div className="flex flex-col divide-y divide-grey-250">
        {
          EditItems.map((item, index) => (
            <EditItem
              key={`${index}-edit-${item.field}`}
              {...item}
            />
          ))
        }
      </div>
    </PublicMobileModalDrawer>
  )
}

export default EditCustomerProfileModal
