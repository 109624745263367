import PublicMobileModalDrawer from 'components/PublicMobileModalDrawer'
import { ProfileModalNavigation } from 'components/user/profile/ProfileModalNavigation'
import useAuth from 'data/hooks/User/useAuth'
import { ModalProps } from 'data/types/modal'
import { useState } from 'react'

interface CustomerProfileModalProps extends ModalProps { }

const CustomerProfileModal = ({
  isOpen,
  onClose,
}: CustomerProfileModalProps) => {
  const { user } = useAuth()

  const [isChildModalOpen, setIsChildModalOpen] = useState(false)

  return (
    <PublicMobileModalDrawer
      isOpen={isOpen}
      onClose={onClose}
      position="right"
      hasFocusTrap
      title={user?.first_name ? `Hello, ${user.first_name}!` : 'Hello!'}
      className={{
        panel: 'flex flex-col overflow-y-auto pb-safe-offset-10',
      }}
      isChildModalOpen={isChildModalOpen}
    >
      <ProfileModalNavigation
        onClose={onClose}
        onIsChildModalOpenChange={setIsChildModalOpen}
      />
    </PublicMobileModalDrawer>
  )
}

export default CustomerProfileModal
