import React from 'react'
import PropTypes from 'prop-types'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper/modules'
import { ArrowLeftIcon } from 'components/icons'
import Image from 'components/Image'

const CarouselPreferencesMobile = ({ content, onClick = () => { } }) => {
  const pagination = {
    type: 'bullets',
    clickable: true,
    bulletClass: '!bg-white !opacity-100 carousel-bullet',
    bulletActiveClass: '!bg-grey-300 !opacity-100 swiper-pagination-bullet-active',
  }

  return (
    <>
      <Swiper
        modules={[Pagination]}
        className="w-full bg-white max-h-[488px] sm:max-h-[600px] h-full"
        slidesPerView={1}
        pagination={pagination}
        observer={true}
      >
        {content && content.map((item, index) => (
          <SwiperSlide className="h-full" key={index}>
            <div className="relative w-full h-full">
              <Image
                src={item.image}
                className="object-cover w-full h-full rounded-t-10"
                layout="fill"
              />
              <div className="absolute inset-0 w-full h-full bg-grey-1000 bg-opacity-20 rounded-t-10" />
              <div className="absolute top-0 bottom-0 w-full h-full">
                <div className="flex flex-col h-full justify-evenly">
                  <h2 className="font-serif font-bold text-left text-white text-bold text-38 drop-shadow-title mx-50">
                    {item.title}
                  </h2>
                  <div className="flex justify-center h-60">
                    <button
                      type="button"
                      className="z-50 flex items-center justify-center rotate-180 bg-white rounded-full cursor-pointer h-60 w-60 shadow-circle-button hover:bg-grey-100"
                      onClick={() => onClick()}
                    >
                      <ArrowLeftIcon />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  )
}

CarouselPreferencesMobile.propTypes = {
  content: PropTypes.array,
  onClick: PropTypes.func,
}

export default CarouselPreferencesMobile
