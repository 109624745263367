import { RadioGroup } from '@headlessui/react'
import classNames from 'classnames'
import Paragraphs from 'components/home/common/typography/Paragraphs'
import { CrossIcon, LoadingIcon, SearchAltIcon } from 'components/icons'
import { config } from 'data/config'
import { TripScheduleServiceSearch } from 'data/enums/tripe-schedule-service-filter'
import { graphql } from 'gql'
import { GeneralService, GeneralServiceOrderByColumn, SortOrder } from 'gql/graphql'
import React, { Fragment, useState } from 'react'
import { useQuery } from 'urql'
import { useDebounce } from 'use-debounce'
import ServiceSlide, { ServiceSlideItemProps } from 'components/sections/ServiceSlide'
import ServiceRequestModalWithAuth from 'components/modals/ServiceRequestModalWithAuth'
import { useQueryClient } from 'react-query'
import Link from 'components/Link'
import Input from 'components/form/Home/Input'
import { Container } from 'data/types/classnames'

export interface MiniServiceSearchClassNames {
  container?: string,
  searchbar?: string,
  disclaimer?: ServiceDisclaimerClassNames,
  serviceCard?: ServiceSlideItemProps['className'],
  grid?: Container,
}

interface MiniServiceSearchProps {
  bookingId?: string
  selectedRegion?: string
  className?: MiniServiceSearchClassNames
  onSearchChange?: (term: string) => void
  showDisclaimer?: boolean
  initialSearchTerm?: string
  hrefOnly?: boolean,
}

const MiniServiceSearch = ({
  bookingId,
  selectedRegion,
  className,
  onSearchChange,
  showDisclaimer = true,
  initialSearchTerm,
}: MiniServiceSearchProps) => {
  const [filter, setFilter] = useState<TripScheduleServiceSearch>(TripScheduleServiceSearch.POPULAR)
  const [searchTerm, setSearchTerm] = useState<string | undefined>(initialSearchTerm)
  const [showServiceModal, setShowServiceModal] = useState(false)

  const [searchDebounced] = useDebounce(searchTerm, 300)
  const queryClient = useQueryClient()

  const getMapping = () => {
    switch (filter) {
      case TripScheduleServiceSearch.EXPERIENCE:
        return config.services.experience.id
      case TripScheduleServiceSearch.SERVICE:
        return config.services.service.id
      default:
        return undefined
    }
  }

  const [{ data, fetching }] = useQuery({
    query: graphql(`
      query Services(
        $page: Int!,
        $perPage: Int!,
        $filters: GeneralServicesFilterInput
        $orderBy: [GeneralServiceOrderByClause!],
      ) {
        services(
          page: $page,
          first: $perPage,
          filters: $filters,
          orderBy: $orderBy
        ) {
          data {
            slug
            form_link
            survey_slug
            category {
              title
            }
            sub_category {
              title
            }
            title
            service_image {
              url
              file_name
            }
            images {
              url
              file_name
            }
            image_alt_text
            description
          }
        }
      }
    `),
    variables: {
      page: 1,
      perPage: 100,
      filters: {
        ...(getMapping() && { categories: [getMapping()!] }),
        ...(selectedRegion && { region: selectedRegion }),
        search: searchDebounced,
      },
      orderBy: [{
        column: GeneralServiceOrderByColumn.Popularity,
        order: SortOrder.Desc,
      }],
    },
  })

  const serviceClick = (formLink: GeneralService['form_link']) => {
    if (formLink) {
      queryClient.refetchQueries(['bookings'])
      setShowServiceModal(true)
    }
  }

  return (<>
    <div className={classNames(
      className?.container,
    )}>
      <div className={classNames(
        'sticky top-0 z-10 pt-10 bg-white',
        className?.searchbar,
      )}>
        <Input
          variant="grey"
          name="service"
          withRing={false}
          placeholder="What service are you looking for?"
          suffix={<SearchAltIcon className="w-16 h-16 text-grey-700" />}
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value)
            onSearchChange?.(e.target.value)
          }}
          autoFocus={false}
        />

        <RadioGroup value={filter} onChange={setFilter} aria-label="Selected Filter" className="flex justify-evenly">
          <RadioGroupItem value={TripScheduleServiceSearch.POPULAR} label="Most Popular" />
          <RadioGroupItem value={TripScheduleServiceSearch.SERVICE} label="Services" />
          <RadioGroupItem value={TripScheduleServiceSearch.EXPERIENCE} label="Experiences" />
        </RadioGroup>

        {showDisclaimer && <ServiceDisclaimer className={className?.disclaimer} />}
      </div>

      <div className={classNames(
        'grid grid-cols-2 md:grid-cols-3 overflow-clip gap-x-16 gap-y-30',
        className?.grid?.container,
      )}>
        {fetching
          ? <LoadingIcon />
          : <>
            {data?.services.data.map((service) => {
              const [subheading, pill] = service.description?.split('\n') ?? [undefined, undefined]
              const serviceData = {
                heading: service.title,
                subheading: subheading,
                pillText: pill ? [pill] : undefined,
                image: {
                  media: service.service_image?.url,
                  alt_text: service.image_alt_text ?? service.title,
                  height: 210,
                  layout: 'fill' as const,
                },
              }

              return service.slug
                ? <Link href={`/services/${service.slug}`} className="cursor-pointer" key={`service-${service.slug}`}>
                  <ServiceSlide
                    className={{
                      serviceCard: { ...className?.serviceCard },
                      image: { container: '!h-[190px]' },
                    }}
                    key={service.slug}
                    isImageLoaded={!fetching}
                    {...serviceData}
                  />
                </Link>
                : ((service.survey_slug && bookingId)
                  ? <Link href={`/home/reservations/${bookingId}/service-request/${service.survey_slug}`} className="cursor-pointer" key={`service-${service.slug}`}>
                    <ServiceSlide
                      className={{
                        serviceCard: { ...className?.serviceCard },
                        image: { container: '!h-[190px]' },
                      }}
                      key={service.slug}
                      {...serviceData}
                      isImageLoaded={!fetching}
                    />
                  </Link>
                  : <button type="button" onClick={() => { serviceClick(service.form_link) }} className="w-full mb-auto" key={`service-${service.slug}`}>
                    <ServiceSlide
                      className={{
                        serviceCard: { ...className?.serviceCard },
                        image: { container: '!h-[190px]' },
                      }}
                      key={service.slug}
                      {...serviceData}
                      isImageLoaded={!fetching}
                    />
                  </button>
                )
            })}
          </>
        }
      </div>
    </div>

    <ServiceRequestModalWithAuth
      isOpen={!!showServiceModal}
      onClose={() => setShowServiceModal(false)}
      service={showServiceModal}
      booking={bookingId}
    />
  </>
  )
}

interface ServiceDisclaimerClassNames {
  container?: string
  text?: string
}

const ServiceDisclaimer = ({ className }: { className?: ServiceDisclaimerClassNames }) => {
  const [isDisclaimerVisible, setIsDisclaimerVisible] = useState(true)

  if (!isDisclaimerVisible) { return null }

  return (
    <div className={classNames(
      'relative py-12 mt-10 bg-blue-30 rounded-10 px-26',
      className?.container,
    )}>
      <button
        className="absolute top-8 right-8 rounded-4 hover:bg-grey-200"
        type="button"
        onClick={() => setIsDisclaimerVisible(false)}
        ref={(node) => node?.focus()}
      >
        <CrossIcon />
      </button>
      <Paragraphs.SM className={classNames(
        'text-center leading-[22px]',
        className?.text,
      )}>
        Select the preferred service from the list and send a request to your dedicated concierge.
        Availability and exact quote will be confirmed within 24 hours.
      </Paragraphs.SM>
    </div>
  )
}

const RadioGroupItem = ({ value, label }: { value: string, label: string }) => {
  return (
    <RadioGroup.Option
      as={Fragment as any}
      value={value}
    >
      {({ checked }) => (
        <div className={classNames(
          'tracking-1/4 text-13 leading-22 mx-12 pt-15 capitalize font-bold cursor-pointer',
          {
            'text-grey-800 border-b-2 pb-8 border-primary-fresh': checked,
            'text-grey-500': !checked,
          },
        )}>
          {label}
        </div>
      )}
    </RadioGroup.Option>
  )
}

export default MiniServiceSearch
