import React from 'react'
import PropTypes from 'prop-types'
import PublicModal from 'components/PublicModal'
import Button from 'components/Button'
import { useState } from 'react'
import { ArrowLeftIcon, ArrowRightIcon } from 'components/icons'
import preferencesOne from 'assets/images/preferences-one.jpg'
import preferencesTwo from 'assets/images/preferences-two.jpg'
import preferencesThree from 'assets/images/preferences-three.jpg'
import PublicModalDrawer from 'components/PublicModalDrawer'
import CarouselPreferences from 'components/carousels/CarouselPreferences'
import CarouselPreferencesMobile from 'components/carousels/CarouselPreferencesMobile'
import { useScreenWidth } from 'data/hooks/useScreenWidth'
import Link from 'components/Link'
import CustomerPreferencesModal from 'components/modals/CustomerPreferencesModal'

const PreferencesModal = ({ isOpen = false, onClose = () => { } }) => {
  const { width } = useScreenWidth()
  const [preferencesOpen, setPreferencesOpen] = useState(false)

  const getContent = () => {
    return [
      {
        image: preferencesOne,
        title: <span>Properties <br />&amp; Destinations</span>,
      },
      {
        image: preferencesTwo,
        title: <span>Services <br />&amp; Experiences</span>,
      },
      {
        image: preferencesThree,
        title: <span>Food <br />&amp; Drink</span>,
      },
    ]
  }

  return (
    <>
      <div className="hidden lg:static">
        <PublicModal
          isOpen={width > 1024 ? isOpen : false} onClose={onClose} transition="center"
          className="w-full max-w-6xl h-[80vh] max-h-[860px]"
          innerPadding="!pt-0 !pb-0 !px-0"
        >
          <div className="flex flex-row">
            <div className="w-1/2">
              <CarouselPreferences
                content={getContent()}
              />
            </div>

            <div className="w-1/2">
              <div className="flex flex-col items-start justify-center gap-20 mt-150 mx-80">
                <p className="font-bold uppercase text-grey-750 text-14 leading-20 tracking-1/2">Personal Preferences</p>
                <h2 className="font-serif font-bold text-grey-900 leading-title tracking-title text-48">
                  Let us know your tastes & preferences
                </h2>
                <p className="mt-10 text-grey-800 text-16 leading-23">
                  We will ask you a few questions to know your taste in the following areas; it will only take 2 minutes!
                </p>

                <div className="mt-36">
                  <Button className="py-16 px-30 text-grey-900" variant="outline-grey">
                    <Link href="/home/preferences" className="flex items-center gap-10 uppercase">
                      <ArrowRightIcon /><span className="font-bold text-14 leading-20 tracking-1/2">Get started</span>
                    </Link>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </PublicModal>
      </div >

      <div className="lg:hidden">
        <PublicModalDrawer
          isOpen={width <= 1024 ? isOpen : false} onClose={onClose}
          className="pb-0 pl-0 pr-0 sm:px-0"
          showHeader={false}
        >
          <button type="button" onClick={() => onClose()} className="absolute top-0 left-0 mt-20 ml-20">
            <ArrowLeftIcon />
          </button>

          <div className="pt-30 flex flex-col justify-between max-h-[calc(100vh-120.07px)] sm:max-h-screen h-full gap-20">
            <div className="w-full h-full">
              <div className="flex flex-col items-start justify-center gap-20 mx-30 sm:mt-100">
                <p className="font-bold uppercase text-grey-750 text-14">Personal Preferences</p>
                <h2 className="text-grey-900 font-serif leading-title tracking-title font-bold text-32 max-w-[260px]">
                  Let us know your tastes & preferences
                </h2>
                <p className="text-grey-800 text-16 max-w-[320px]">
                  We will ask you a few questions to know your taste in the following areas; it will only take 2 minutes!
                </p>
              </div>
            </div>

            <div className="w-full h-full max-h-[488px] sm:max-h-[600px] relative">
              <CarouselPreferencesMobile
                content={getContent()}
                onClick={() => setPreferencesOpen(true)}
              />
            </div>
          </div>
        </PublicModalDrawer>
      </div>

      <CustomerPreferencesModal
        isOpen={preferencesOpen === true}
        onClose={() => {
          setPreferencesOpen(false)
          onClose()
        }}
      />
    </>
  )
}

PreferencesModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
}

export default PreferencesModal
