import { formatCurrency } from '@ama-selections/ui'
import { User } from '@sentry/nextjs'
import classNames from 'classnames'
import { RestUser } from 'data/types/user'
import React, { useMemo } from 'react'
import logoImage from 'assets/images/logo-no-tagline-white.png'
import Image from 'components/Image'
import { Membership } from 'data/enums/membership-type'

interface MembershipCardProps {
  user: RestUser | User
  className: {
    container?: string
  }
}

const MembershipCard = ({
  user,
  className,
}: MembershipCardProps) => {

  const membershipColour = useMemo(() => {
    switch (user?.membership_type) {
      case Membership.SILVER:
        return {
          background: 'bg-grey-700',
        }
      case Membership.GOLD:
        return {
          background: 'bg-primary-fresh',
        }
      default:
        return {
          background: 'bg-[#322d4b]',
        }
    }
  }, [user])

  return (
    <div className={classNames(
      'flex flex-col justify-between',
      'rounded-[14px] relative overflow-hidden select-none',
      'h-[220px] w-[320px]',
      'pt-[17px] pr-[23px] pl-[16px] pb-25',
      'bg-gradient-to-tl from-transparent to-transparent via-[#ffffff44]',
      'via-30% to-70%',
      membershipColour.background,
      className?.container,
    )}>
      <div className={classNames(
        'flex flex-col items-end',
      )}>
        <span className="font-medium text-white font-poppins text-12 leading-22 tracking-1/4">
          {user.first_name}
        </span>

        <span className="font-semibold text-white font-poppins text-18 leading-22 tracking-1/4">
          {user.id?.toString().padStart(8, '0')}
        </span>
      </div>

      <div className="flex justify-between">
        <Image
          src={logoImage}
          height={40}
          width={73.82}
        />
        <div className="flex flex-col items-end justify-between">
          <span className="font-semibold text-white font-poppins text-14 leading-22 tracking-1/4">
            {formatCurrency(user.credit)}
          </span>
          <span className="font-medium text-white capitalize font-poppins text-12 leading-22 tracking-1/4">
            {user.membership_type} Member
          </span>
        </div>
      </div>
    </div>
  )
}

export default MembershipCard
