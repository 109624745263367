import { useEffect, useRef, useState } from 'react'
import { ModalProps } from 'data/types/modal'
import PublicMobileModal from 'components/PublicMobileModal'
import useAuth from 'data/hooks/User/useAuth'
import Button from 'components/home/common/Button'
import { CloudUploadIcon, ProfileCameraIcon, UploadIcon } from 'components/icons'
import { getProfileImage } from '@ama-selections/ui'
import { SubmitHandler, useForm } from 'react-hook-form'
import Image from 'components/Image'
import classNames from 'classnames'
import { useMutation } from 'react-query'
import { RestUserInput } from 'data/types/input'
import ApiClient from 'data/api/api_client'
import { useDispatch } from 'react-redux'
import useErrorHandler from 'data/hooks/useErrorHandler'
import { setUser } from 'store/auth'
import { AxiosError } from 'axios'
import { useNotifier } from 'react-headless-notifier'
import Notification from 'components/Notification'

type EditProfileImageModalFormFields = Pick<RestUserInput, 'profile_image'>

export const EditProfileImageModal = ({
  isOpen,
  onClose,
}: ModalProps) => {
  const { notify } = useNotifier()

  const { user } = useAuth()
  const dispatch = useDispatch()
  const handleError = useErrorHandler()
  const [hasUploaded, setHasUploaded] = useState(false)

  const { setValue, setError, reset, formState: { errors }, handleSubmit, clearErrors } = useForm<EditProfileImageModalFormFields>()

  const fileRef = useRef<HTMLInputElement>(null)
  const selfieRef = useRef<HTMLInputElement>(null)

  const [file, setFile] = useState<File | undefined>()
  const [previewUrl, setPreviewUrl] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (!user) { return }

    setPreviewUrl(getProfileImage(user))
  }, [user])

  useEffect(() => {
    if (!file) { return }

    setValue('profile_image', file)
    setPreviewUrl(URL.createObjectURL(file))

    return () => {
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl)
      }
    }
  }, [file])

  const customerMutation = useMutation(
    (details: EditProfileImageModalFormFields) => ApiClient.users.updateUser(user!.id, details),
    {
      onSuccess: (updatedUser) => {
        setHasUploaded(true)
        dispatch(setUser(updatedUser))
        reset()
        onClose()
      },
      onError: (error: AxiosError) => {
        if (error.response?.status === 422) {
          handleError(error, setError)
        } else {
          notify(
            <Notification
              message="Failed to update profile image, please try again."
              type="error"
            />,
          )
          onClose()
        }
      },
    },
  )

  const onSubmit: SubmitHandler<EditProfileImageModalFormFields> = (data) => {
    customerMutation.mutate(data)
  }

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    clearErrors('profile_image')
    setFile(e.target.files?.[0])
  }

  return (
    <PublicMobileModal
      hasFocusTrap
      isOpen={isOpen}
      onClose={onClose}
      onOpen={() => {
        reset()
        setHasUploaded(false)
        setFile(undefined)
      }}
      className={{
        panel: 'px-30 pt-60 pb-40',
      }}
    >
      <form
        className="flex flex-col gap-y-20"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="flex flex-col bg-grey-100 rounded-16 py-25 px-21">
          <div className="relative flex items-center justify-center">
            {
              previewUrl
                ? (
                  <Image
                    src={previewUrl}
                    alt=""
                    height={100}
                    width={100}
                    className="object-cover rounded-full"
                  />
                )
                : (
                  <UploadIcon className="text-50 m-25" />
                )
            }
          </div>

          {
            file
              ? (
                <div className="flex flex-col mt-16 text-center tracking-3/4">
                  <p className="text-grey-800 text-14 leading-24">
                    {file?.name} - {((file?.size ?? 0) / 1000 / 1000).toFixed(1)}mb
                  </p>

                  <p className={classNames(
                    'font-semibold font-poppins text-15 leading-24 mt-10',
                    {
                      'text-primary-fresh': hasUploaded,
                      'text-grey-900': !hasUploaded,
                    },
                  )}>
                    {
                      errors.profile_image
                        ? (
                          <span className="text-red-500">
                            {errors.profile_image.message}
                          </span>
                        )
                        : (
                          hasUploaded
                            ? 'Uploaded'
                            : 'Ready to upload'
                        )
                    }
                  </p>
                </div>
              )
              : undefined
          }

          {
            (!file || errors.profile_image)
              ? (
                <div className="flex gap-x-10 mt-26">
                  <Button
                    type="button"
                    style="small"
                    className={{
                      button: '!ring-opacity-100 hover:!ring-primary-dark',
                    }}
                    block
                    suffixIcon={<CloudUploadIcon />}
                    onClick={() => fileRef.current?.click()}
                  >
                    Upload
                  </Button>

                  <input
                    type="file"
                    accept="image/*"
                    className="hidden"
                    ref={fileRef}
                    onChange={onFileChange}
                  />

                  <Button
                    type="button"
                    style="small"
                    variant="dark-grey"
                    block
                    suffixIcon={<ProfileCameraIcon className="stroke-[1.5]" />}
                    onClick={() => selfieRef.current?.click()}
                  >
                    Photo
                  </Button>

                  <input
                    type="file"
                    accept="image/*"
                    className="hidden"
                    capture="user"
                    ref={selfieRef}
                    onChange={onFileChange}
                  />
                </div>
              )
              : undefined
          }
        </div>

        <Button
          type="submit"
          style="modal"
          disabled={!file}
          isLoading={customerMutation.isLoading}
        >
          Save
        </Button>
      </form>
    </PublicMobileModal>
  )
}
