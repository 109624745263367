import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

export const useUserDashboard = () => {
  const router = useRouter()
  const [isOnUserDashboard, setIsOnUserDashboard] = useState(false)

  useEffect(() => {
    // get the position in the router path that
    // indicates we are on the user dashboard
    const urlPrefix = router.asPath.split('/')[1]

    if (urlPrefix === 'home') {
      setIsOnUserDashboard(true)
    }
  }, [router.asPath])

  return {
    isOnUserDashboard,
  }
}
