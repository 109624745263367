import React from 'react'
import PropTypes from 'prop-types'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Navigation } from 'swiper/modules'
import { CarouselLeftChevron, CarouselRightChevron } from 'components/icons'
import Image from 'components/Image'

const CarouselPreferences = ({ content }) => {
  const navigationPrevRef = React.useRef(null)
  const navigationNextRef = React.useRef(null)
  const buttonClasses = 'absolute visible z-10 top-1/2 cursor-pointer'

  const pagination = {
    type: 'bullets',
    clickable: true,
    bulletClass: '!bg-white !opacity-100 carousel-bullet',
    bulletActiveClass: '!bg-grey-300 !opacity-100 swiper-pagination-bullet-active',
  }

  return (
    <Swiper
      modules={[Pagination, Navigation]}
      className="bg-white h-[80vh] max-h-[860px]"
      slidesPerView={1}
      pagination={pagination}
      navigation={{
        prevEl: navigationPrevRef.current,
        nextEl: navigationNextRef.current,
      }}
      onSwiper={(swiper) => {
        setTimeout(() => {
          // Timeout until prevEl & nextEl refs are defined
          swiper.params.navigation.prevEl = navigationPrevRef.current
          swiper.params.navigation.nextEl = navigationNextRef.current

          // Re-initialise navigation
          swiper.navigation.destroy()
          swiper.navigation.init()
          swiper.navigation.update()
        })
      }}
      observer={true}
    >
      {content && content.map((item, index) => (
        <SwiperSlide key={index}>
          <div className="relative w-full h-full">
            <Image
              src={item.image}
              className="object-cover w-full h-full"
              loading="lazy"
              layout="fill"
            />
            <div className="absolute inset-0 w-full h-full bg-grey-1000 bg-opacity-20" />
            <h2 className="absolute text-center text-white font-bold font-serif text-38 drop-shadow-title left-0 right-0 top-[165px] mx-50">
              {item.title}
            </h2>
          </div>
        </SwiperSlide>
      ))}

      <div ref={navigationPrevRef} className={`${buttonClasses} left-20`}><CarouselLeftChevron /></div>
      <div ref={navigationNextRef} className={`${buttonClasses} right-20`}><CarouselRightChevron /></div>
    </Swiper>
  )
}

CarouselPreferences.propTypes = {
  content: PropTypes.array,
}

export default CarouselPreferences
