import PublicMobileModal, { PublicMobileModalProps } from 'components/PublicMobileModal'
import logo from 'assets/images/logo-no-tagline.jpg'
import Image from 'components/Image'
import Paragraphs from 'components/home/common/typography/Paragraphs'
import ShareButton from 'components/home/ShareButton'
import { Share } from 'data/enums/share-target'
import { getShareLink } from 'data/helpers/share'
import { useCopyToClipboard } from 'data/hooks/useCopyToClipboard'
import { ReactChild } from 'data/types/types'
import { ModalProps } from 'data/types/modal'
import WishlistUsersModal from '../bookings/shared/WishlistUsersModal'
import { useEffect, useState } from 'react'
import { ShareWishlistModalProps } from '../ShareWishlistModal'
import ProfileIconsButton from 'components/ProfileIconsButton'
import { Property, PropertyPageQuery, RandomRegionQuery } from 'gql/graphql'
import PropertyCard from 'components/property/PropertyCard'

type ShareLink = {
  target: Exclude<Share, Share.CLIPBOARD>,
  icon: JSX.Element
  text?: string
} | {
  target: Share.CLIPBOARD,
  icon: JSX.Element
  text?: string
}

type ShareLinkOptionals = {
  onClick: () => void
  href?: never
} | {
  onClick?: never
  href: string
}

interface ShareModalProps extends Omit<ShareModalContentProps, 'isModelModalOpen' | 'setIsModelModelOpen'>, Omit<PublicMobileModalProps, 'title' | 'children'> {
  children?: ReactChild
  randomRegion?: RandomRegionQuery
}

export interface ShareModalContentProps extends Pick<ModalProps, 'onClose'> {
  title: string
  description?: string
  links?: (ShareLink & ShareLinkOptionals)[]
  icon?: ReactChild
  size?: 'sm' | 'md' | 'xl'
  model?: ShareWishlistModalProps['wishlist']
  refetch?: () => void
  isModelModalOpen: boolean
  setIsModelModelOpen: (isOpen: boolean) => void
  property?: Property | PropertyPageQuery['property']
}

const ShareModal = ({
  isOpen,
  onClose,
  onOpen,
  refetch,
  model,
  randomRegion,
  children,
  isChildModalOpen,
  ...props
}: ShareModalProps) => {
  const [isModelModalOpen, setIsModelModalOpen] = useState(false)

  return (
    <PublicMobileModal
      isChildModalOpen={isChildModalOpen || isModelModalOpen}
      isOpen={isOpen}
      onClose={onClose}
      onOpen={onOpen}
      className={{
        panel: 'flex flex-col pt-[66px] px-24 pb-50',
      }}
    >
      <>
        <ShareModalContent
          onClose={onClose}
          refetch={refetch}
          model={model}
          isModelModalOpen={isModelModalOpen}
          setIsModelModelOpen={setIsModelModalOpen}
          {...props}
        />

        {children}

        {
          model && (
            <WishlistUsersModal
              isOpen={isModelModalOpen}
              wishlist={model}
              refetch={refetch}
              randomRegion={randomRegion as RandomRegionQuery}
              onClose={() => setIsModelModalOpen(false)}
            />
          )
        }
      </>
    </PublicMobileModal>
  )
}

const ShareModalContent = ({
  title,
  description,
  links,
  onClose,
  icon,
  size = 'md',
  model,
  refetch,
  isModelModalOpen,
  setIsModelModelOpen,
  property,
}: ShareModalContentProps) => {
  useEffect(() => {
    if (isModelModalOpen) {
      refetch?.()
    }
  }, [isModelModalOpen])

  return (
    <>
      <div className="w-full text-center mb-25">
        {icon
          ? icon
          : <Image src={logo} alt="logo" width={110} height={60} />
        }
      </div>

      <Paragraphs.XL className="text-center mb-15 !leading-24 !font-bold">
        {title}
      </Paragraphs.XL>

      { property
        ? <PropertyCard
          property={{
            ...property,
            wishlists: {
              paginatorInfo: {
                total: 0,
              },
            },
          }}
          variant="hide-wishlist"
          className={{
            container: 'mb-20',
          }}
        />
        : null
      }

      { description
        ? <Paragraphs.LG className="mb-40 text-center text-grey-650">
          {description}
        </Paragraphs.LG>
        : null
      }

      {
        model && model?.users && model?.users?.length > 0 && (
          <div className="flex items-center justify-center gap-[11px] mb-30 mt-[-15px]">
            <ProfileIconsButton
              users={[
                ...(model?.users.filter(user => user?.id !== model?.owner?.id) ?? []),
                ...(model?.userInvites ?? []),
              ]}
              size={size}
              maxIcons={3}
              onClick={() => setIsModelModelOpen(true)}
            />
          </div>
        )}


      <div className="flex flex-col gap-15 text-22">
        {links && links.map((link) => getShareButton(link, onClose))}
      </div>
    </>
  )
}

const getShareButton = (link: (ShareLink & ShareLinkOptionals), onClose: () => void | undefined) => {
  const { copyToClipboard } = useCopyToClipboard()
  const shareLink = getShareLink(link.target, link.href, link.text)

  switch (link.target) {
    case Share.CLIPBOARD:
      return (
        <ShareButton
          key={`share-${link.target}`}
          shareTarget={link.target}
          onClick={() => {
            copyToClipboard(shareLink)
            onClose?.()
          }}
          icon={link.icon}
        />
      )

    default:
      return (
        <ShareButton
          key={`share-${link.target}`}
          href={shareLink}
          onClick={link.onClick}
          shareTarget={link.target}
          icon={link.icon}
        />
      )
  }
}

export default ShareModal
