const getNumberArray = (length: number) => {
  return Array(length).fill(null).map((_, i) => i)
}

const getLoadedImagesArray = (
  swiperIndex: number,
  imagesEitherSide: number,
  imagesLength: number,
  numberArray: number[],
  loadedImages: number[],
) => {
  const lowNumber = swiperIndex - imagesEitherSide >= 0
    ? swiperIndex - imagesEitherSide
    : 0

  const highNumber = swiperIndex + imagesEitherSide <= imagesLength
    ? swiperIndex + imagesEitherSide
    : imagesLength - 1

  return [...new Set([...loadedImages, ...numberArray.slice(lowNumber, highNumber + 1)])]
}

export {
  getNumberArray,
  getLoadedImagesArray,
}
