import { Dialog, Transition } from '@headlessui/react'
import IconButton from 'components/IconButton'
import { ArrowLeftIcon, CheckBubbleIcon, CrossIcon } from 'components/icons'
import { config } from 'data/config'
import { Membership } from 'data/enums/membership-type'
import React, { Fragment, useRef, useState } from 'react'
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'
import Button from '../common/Button'
import silverImage from 'assets/images/membership-silver.jpeg'
import goldImage from 'assets/images/membership-gold.jpeg'
import Image, { StaticImageData } from 'components/Image'
import { Navigation } from 'swiper/modules'
import classNames from 'classnames'
import { useDispatch } from 'react-redux'
import useAuth from 'data/hooks/User/useAuth'
import PreferencesModal from 'components/modals/PreferencesModal'
import { setLoginModalOpen } from 'store/auth'
import { setTier } from 'store/preferences'

interface MembershipPromotionalModalProps {
  isOpen: boolean
  onClose: () => void
  initialOption?: Membership
}

type MembershipSlideOption = {
  title: string
  yearlyPrice: number
  descriptions: string[]
  type: Membership
  image: StaticImageData
  features: string[]
}

const membershipOptions: MembershipSlideOption[] = [
  {
    type: Membership.SILVER,
    title: 'Silver',
    yearlyPrice: config.membership.silver.yearlyPrice,
    descriptions: [
      'Enjoy a free trial of our premium membership',
      'Access to all features',
      'No credit card required',
    ],
    image: silverImage,
    features: config.membership.silver.features,
  },
  {
    type: Membership.GOLD,
    title: 'Gold',
    yearlyPrice: config.membership.gold.yearlyPrice,
    descriptions: [
      'Enjoy a free trial of our premium membership',
      'Access to all features',
      'No credit card required',
    ],
    image: goldImage,
    features: config.membership.gold.features,
  },
]

const MembershipPromotionalModal = ({
  isOpen,
  onClose,
  initialOption,
}: MembershipPromotionalModalProps) => {
  const slideClassNames = 'flex items-center justify-center w-full h-full'

  const navigationNextRef = useRef(null)
  const navigationPrevRef = useRef(null)

  const buttonClasses = 'absolute swiper-button top-1/2 cursor-pointer -mt-14 z-50 hidden lg:flex'

  return (
    <Transition
      show={isOpen}
      as={Fragment as any}
      enter="transition ease-out duration-100"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <Dialog
        onClose={onClose}
      >
        <Dialog.Panel
          className="fixed top-0 left-0 z-50 flex w-full h-full bg-black bg-opacity-50"
        >
          {/* mobile bar */}
          <div className="absolute top-0 z-10 flex justify-between w-full px-20 pt-safe-offset-20 lg:hidden">
            <IconButton
              onClick={() => onClose?.()}
              className="z-10 !p-0 text-white bg-transparent"
            >
              <ArrowLeftIcon className="stroke-2" />
            </IconButton>
            <IconButton
              onClick={() => onClose?.()}
              className="z-10 !p-0 text-white bg-transparent"
            >
              <CrossIcon />
            </IconButton>

          </div>
          <Swiper
            initialSlide={membershipOptions.findIndex(option => option.type === initialOption)}
            modules={[Navigation]}
            className="items-center justify-center w-full h-full"
            observer={true}
            navigation={{
              prevEl: navigationPrevRef.current,
              nextEl: navigationNextRef.current,
            }}
            onSwiper={(swiper: SwiperClass) => {
              setTimeout(() => {
                if (swiper.params.navigation && typeof swiper.params.navigation !== 'boolean') {
                  swiper.params.navigation.prevEl = navigationPrevRef.current
                  swiper.params.navigation.nextEl = navigationNextRef.current
                }
                swiper.navigation.destroy()
                swiper.navigation.init()
                swiper.navigation.update()
              })
            }}
          >
            {membershipOptions.map((option, index) => (
              <SwiperSlide
                key={index}
                className={slideClassNames}
              >
                <MembershipPromotionalSlide
                  option={option}
                  onClose={onClose}
                  index={index}
                  navigationNextRef={navigationNextRef}
                  navigationPrevRef={navigationPrevRef}
                />
              </SwiperSlide>
            ))}

            <div
              ref={navigationPrevRef}
              className={classNames(buttonClasses, 'swiper-button-prev left-[calc(50vw-500px)]')}
            />

            <div
              ref={navigationNextRef}
              className={classNames(buttonClasses, 'swiper-button-next right-[calc(50vw-500px)]')}
            />
          </Swiper>
        </Dialog.Panel>
      </Dialog>
    </Transition>
  )
}

interface MembershipPromotionalSlideProps {
  option: MembershipSlideOption
  onClose?: () => void
  index: number
  navigationNextRef: React.RefObject<HTMLDivElement>
  navigationPrevRef: React.RefObject<HTMLDivElement>
}

export const MembershipPromotionalSlide = ({
  option,
  onClose,
  index,
  navigationNextRef,
  navigationPrevRef,
}: MembershipPromotionalSlideProps) => {
  const { user } = useAuth()
  const dispatch = useDispatch()

  const handleClick = (tier: Membership) => {
    if (user) {
      dispatch(setTier(tier))
      setPreferencesModalOpen(true)
    } else {
      dispatch(setLoginModalOpen(true))
    }
  }

  const nextItem = membershipOptions[(index + 1) % membershipOptions.length]

  const [preferencesModalOpen, setPreferencesModalOpen] = useState(false)

  return (
    <>
      <div className="h-full lg:max-h-[800px] lg:max-w-[850px] w-full lg:rounded-24 relative overflow-hidden">
        <IconButton
          className="absolute top-0 right-0 z-10 hidden text-white bg-transparent lg:block p-25"
          onClick={() => onClose?.()}
        >
          <CrossIcon />
        </IconButton>

        <div className="z-10 flex flex-col items-center justify-center w-full h-full gap-5 px-40">
          <div className="pb-16 font-serif font-bold text-white text-28 leading-22 tracking-1/4">
            {option.title} Member
          </div>

          <div className="text-white text-14 font-poppins leading-22 tracking-1/4 pb-60">
            {option.yearlyPrice}€/year
          </div>

          <div className="flex flex-col gap-5">
            {option.features.map((feature, index) => (
              <div key={index} className="flex items-center gap-12 text-white">
                <div>
                  <CheckBubbleIcon className="min-h-15 min-w-15" />
                </div>
                <span
                  className="font-medium text-14 leading-22 tracking-1/4 font-poppins"
                >
                  {feature}
                </span>
              </div>
            ))}
          </div>
        </div>


        <div className="absolute bottom-0 z-10 flex flex-col items-center w-full px-20 pb-50 lg:pb-80">
          <button
            className="flex items-center justify-center gap-10 w-max lg:hidden"
            onClick={() => {
              index === 0 ? navigationNextRef.current?.click() : navigationPrevRef.current?.click()
            }}
          >
            <ArrowLeftIcon className={classNames(
              'w-20 h-20 text-white stroke-2',
              { 'order-2 rotate-180': index === 0 },
            )} />

            <span className="text-white font-poppins text-14 leading-22 tracking-1/2">
              View {nextItem.title} Membership
            </span>
          </button>

          <Button
            variant="grey"
            style="modal"
            className={{
              button: 'w-full max-w-[326px] mx-auto mt-[53px] lg:mt-0',
            }}
            disabled={user?.membership_type === option.type}
            onClick={() => handleClick(option.type as Membership)}
          >
            Upgrade to {option.title}
          </Button>
        </div>

        <div className="absolute inset-0 z-[-1]">
          <div className="absolute inset-0 z-10 w-full h-full bg-black bg-opacity-40 blur-[350px]" />
          <Image
            src={option.image}
            alt="Membership image"
            layout="fill"
            className="object-cover brightness-90"
          />
        </div>
      </div>
      <PreferencesModal
        isOpen={preferencesModalOpen}
        onClose={() => setPreferencesModalOpen(false)}
      />
    </>
  )
}

export default MembershipPromotionalModal
