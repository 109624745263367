import React, { RefObject } from 'react'
import classNames from 'classnames'
import { StaticImageData } from 'components/Image'

interface Image {
  url: string | StaticImageData
  alt_tag?: string
  alt_text?: string
}

interface PropTypes {
  navigationRef: RefObject<HTMLDivElement>;
  direction: 'left' | 'right';
  buttonClasses: string;
  images: Image[];
  leftButtonClasses: string;
  rightButtonClasses: string;
}

const NavigationButton = ({
  navigationRef,
  direction,
  buttonClasses,
  images,
  leftButtonClasses,
  rightButtonClasses,
}: PropTypes) => {
  const isLeft = direction === 'left'

  return (
    <div
      ref={navigationRef}
      className={classNames(
        'transition-all lg:pointer-events-none',
        isLeft ? leftButtonClasses : rightButtonClasses,
        buttonClasses,
        {
          'opacity-0': images.length === 0,
          'group-hover:pointer-events-auto': images.length > 0,
        },
      )}
      onClick={(e) => e.stopPropagation()}
    />
  )
}

export default NavigationButton
